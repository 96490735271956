import { mapLocalizedString } from '../../../util/localization';

export type LocalizedString =
  | string
  | {
      de?: string;
      en?: string;
    };

export interface FetchResponse {
  events: Event[];
}
export interface VenueEventFlat {
  eventSeries?: {
    name?: LocalizedString;
  };
  id: string;
  masterId?: string;
  maxTicketsPerUser: number;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  seasonTicketLegalRecipientShouldBeCollected: boolean;
  preliminaryDateText?: LocalizedString;
  salesInformation: LocalizedString;
  startDate?: string;
  subtitle?: LocalizedString;
  team1ImageUrl?: string;
  team2ImageUrl?: string;
  title: LocalizedString;
  venueLayout: string;
  venuePlans?: [{ id?: string }];
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
}

export interface VenueEvent {
  eventSeries?: string;
  id: string;
  masterId?: string;
  maxTicketsPerUser: number;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  salesInformation: LocalizedString;
  seasonTicketLegalRecipientShouldBeCollected: boolean;
  preliminaryDateText?: string;
  startDate?: Date | null;
  subtitle?: string;
  team1ImageUrl?: string;
  team2ImageUrl?: string;
  title: string;
  venueLayout: string;
  venuePlanId?: string;
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
}

export const mapVenueEvent = (flatVenueEvent: VenueEventFlat): VenueEvent => {
  return {
    eventSeries: mapLocalizedString(flatVenueEvent.eventSeries?.name),
    id: flatVenueEvent.id,
    masterId:
      (flatVenueEvent.masterId?.length ?? 0) > 0
        ? flatVenueEvent.masterId
        : undefined,
    maxTicketsPerUser: flatVenueEvent.maxTicketsPerUser,
    personalDataOnTicketIsRequired:
      flatVenueEvent.personalDataOnTicketIsRequired,
    personalDataOnTicketShouldBeCollected:
      flatVenueEvent.personalDataOnTicketShouldBeCollected,
    seasonTicketLegalRecipientShouldBeCollected:
      flatVenueEvent.seasonTicketLegalRecipientShouldBeCollected,
    preliminaryDateText: mapLocalizedString(flatVenueEvent.preliminaryDateText),
    startDate: flatVenueEvent.startDate
      ? new Date(flatVenueEvent.startDate)
      : null,
    subtitle: mapLocalizedString(flatVenueEvent.subtitle),
    team1ImageUrl:
      (flatVenueEvent.team1ImageUrl?.length ?? 0) > 0
        ? flatVenueEvent.team1ImageUrl
        : undefined,
    team2ImageUrl:
      (flatVenueEvent.team2ImageUrl?.length ?? 0) > 0
        ? flatVenueEvent.team2ImageUrl
        : undefined,
    title: mapLocalizedString(flatVenueEvent.title) ?? '',
    venueLayout: flatVenueEvent.venueLayout,
    venuePlanId: flatVenueEvent.venuePlans?.[0].id,
    venuePlanVersionId: flatVenueEvent.venuePlanVersionId,
    disableVisualSelection: flatVenueEvent.disableVisualSelection,
    salesInformation: flatVenueEvent.salesInformation,
  };
};
