import { Action, ActionType, SessionState } from './types';
import jwtDecode from 'jwt-decode';

const initialState: SessionState = {
  arePlacesSubmitted: false
};

export const reducer = (state = initialState, action: Action): SessionState => {
  switch (action.type) {
    case ActionType.SET_JWT: {
      const { jwt } = action.payload;
      const jwtDecoded = jwtDecode<{ [key: string]: string }>(jwt);
      const user = {
        firstName: jwtDecoded?.first_name,
        id: jwtDecoded?.sub,
        lastName: jwtDecoded?.last_name,
      };
      return {
        ...state,
        jwt,
        jwtDecoded,
        user,
      };
    }
    case ActionType.SET_SALES_CHANNEL: {
      const { salesChannel } = action.payload;
      return { ...state, salesChannel };
    }
    case ActionType.SET_SALES_RULE_ID: {
      const { salesRuleId } = action.payload;
      return { ...state, salesRuleId };
    }
    case ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID: {
      const { purchaseForTicketHolderId } = action.payload;
      return { ...state, purchaseForTicketHolderId };
    }
    case ActionType.SET_SUBSCRIPTION_ID: {
      const { subscriptionId } = action.payload;
      return { ...state, subscriptionId };
    }
    case ActionType.SET_QUEUEITOKEN: {
      const { queueittoken } = action.payload;
      return { ...state, queueittoken };
    }
    case ActionType.SET_SELECTED_RIGHTS_PROVIDER: {
      const { selectedRightsProvider } = action.payload;
      return { ...state, selectedRightsProvider };
    }
    case ActionType.SET_ARE_PLACES_SUBMITTED: {
      const { arePlacesSubmitted } = action.payload;
      return { ...state, arePlacesSubmitted };
    }
    default: {
      return state;
    }
  }
};
