export enum ActionType {
  GET_BLOCKS = 'BLOCK::GET_BLOCKS',
  ERROR = 'BLOCK::ERROR',
  FETCH = 'BLOCK::FETCH',
  SUCCESS = 'BLOCK::SUCCESS',
}

// single block
export interface Block {
  id: string;
  label: {
    de: string;
    en: string;
  };
  backendNameShort: string;
  entrance: {
    de: string;
    en: string;
  };
  venuePlan: {
    id: string;
  };
  blockType: string;
}

// block state
export interface BlockState {
  error?: Error;
  fetching: boolean;
  blocks?: Block[];
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { blocks: Block[] };
  type: typeof ActionType.SUCCESS;
}

export type Action = ErrorAction | FetchAction | SuccessAction;
