import { VenueEvent } from './VenueEvent';

export type { VenueEvent, VenueEventFlat } from './VenueEvent';
export { mapVenueEvent } from './VenueEvent';

export enum ActionType {
  ERROR = 'VENUE_EVENT::ERROR',
  FETCH = 'VENUE_EVENT::FETCH',
  SUCCESS = 'VENUE_EVENT::SUCCESS',
  SET_CONSECUTIVE_SEATS = 'VENUE_EVENT::SET_CONSECUTIVE_SEATS',
  SET_PRICING_CATEGORY = 'VENUE_EVENT::SET_PRICING_CATEGORY',
  ADD_TICKET = 'VENUE_EVENT::ADD_TICKET',
}

export interface VenueEventState {
  error?: Error;
  fetching: boolean;
  venueEvent?: VenueEvent;
  ticketSelection: {
    consecutiveSeats: number;
  };
}

export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

export interface FetchAction {
  type: typeof ActionType.FETCH;
}

export interface SuccessAction {
  payload: { venueEvent: VenueEvent };
  type: typeof ActionType.SUCCESS;
}

export interface SetConsecutiveSeatsAction {
  payload: { consecutiveSeats: number };
  type: typeof ActionType.SET_CONSECUTIVE_SEATS;
}

export interface AddTicketAction {
  type: typeof ActionType.ADD_TICKET;
}

export type Action =
  | ErrorAction
  | FetchAction
  | SuccessAction
  | SetConsecutiveSeatsAction;
