import React, { useState, useEffect, ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTenantConfig, useTenantConfig } from '../../state/TenantConfig';
import Countdown from '../Countdown';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { useSession } from '../../state/Session';
// import { Button } from '@mui/material';
// import { useLocale } from '../../state/Localization';
// import { redirectToLogin, signOut } from '../../auth';
// import { getTenantSlugFromURL } from '../../util/apiRequest';
import './Header.scss';

interface HeaderProps {
  expires?: Date;
}

const Header: React.FC<HeaderProps> = ({expires}) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const { user, jwt } = useSession();
  // const { strings } = useLocale();

  const { tenantConfig } = useTenantConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tenantConfig) {
      dispatch(getTenantConfig());
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentUrl', window.location.href);
  }, []);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const onHandleSignIn = () => {
  //   handleClose();
  //   redirectToLogin();
  // };

  // const onHandleSignOut = () => {
  //   const tenantSlug = getTenantSlugFromURL();
  //   signOut(jwt as string, tenantSlug, window.location.href);
  //   handleClose();
  // };

  return (
    <header className='header'>
      <div className="header__wrapper">
        <div className="header__left">
          {expires &&
            <div className="countdown">
              <Countdown to={new Date(expires)} />
            </div>
          }
        </div>
        <div className="header__center">
          {tenantConfig?.websiteUrl
            ? <a href={tenantConfig.websiteUrl} className='header__logo'>
                {/* TODO: dynamically set tenant logo */}
                <img src='/icons/icon.png' alt="Logo" />
              </a>
            : <span className='header__logo'>
                {/* TODO: dynamically set tenant logo */}
                <img src='/icons/icon.png' alt="Logo" />
              </span>
          }
        </div>
        <div className="header__right">
          {/* <Button className='header__menu-button'
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              if (anchorEl) {
                handleClose();
              } else {
                handleClick(e);
              }
            }}
          >
            {user?.firstName ? `${user.firstName} ${user.lastName}` : strings.Anonymoys_UserName}
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {user?.firstName ?
              <MenuItem onClick={onHandleSignOut}>Sign out</MenuItem> :
              <MenuItem onClick={onHandleSignIn}>Sign in</MenuItem>}
          </Menu> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
