import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { FormCheckoutFieldsComponent } from '../../state/Checkout';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FormCheckoutFields } from '../../state/Checkout';
import style from './style.module.css';
import { COUNTRIES } from "../../util/countries";

interface FormikErrors {
  [key: string]: FormCheckoutFields;
}

const CheckoutContactFields: React.FC<FormCheckoutFieldsComponent> = ({
  contactType,
  className,
  i18nFormFields: {
    salutation,
    salutationM,
    salutationF,
    salutationD,
    firstName,
    lastName,
    address1,
    address2,
    companyName,
    postalCode,
    city,
    country,
    email
  },
}) => {
  return (
    <div className={className}>
      <div className={style.companyName}>
          <Field
              type="text"
              name={`${contactType}.companyName`}
              id={`${contactType}companyName`}
          >
              {({ field }: { field: { name: string, value: string } }) => (
                  <TextField
                      fullWidth
                      className={style.formFieldInput}
                      label={companyName}
                      variant="outlined"
                      {...field}
                  />
              )}
          </Field>
      </div>
      <div className={style.salutation}>
        <Field name={`${contactType}.salutation`}>
            {({ field }: FieldProps) => (
                <FormControl fullWidth variant="outlined" className={style.formFieldInput}>
                    <InputLabel htmlFor={`${contactType}salutation`}>{salutation}</InputLabel>
                    <Select
                        label={salutation}
                        {...field}
                        id={`${contactType}salutation`}
                    >
                        <MenuItem value="m">{salutationM}</MenuItem>
                        <MenuItem value="f">{salutationF}</MenuItem>
                        <MenuItem value="d">{salutationD}</MenuItem>
                    </Select>
                </FormControl>
            )}
        </Field>
      </div>
      <div className={style.firstName}>
        <Field
          type="text"
          name={`${contactType}.firstName`}
          id={`${contactType}firstName`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              label={firstName}
              error={!!form.errors[contactType]?.firstName}
              variant="outlined"
              className={style.formFieldInput}
              {...field}
            />
          )}
        </Field>
        <ErrorMessage
          name={`${contactType}.firstName`}
          component="div"
          className={style.formFieldErrorMessage}
        />
      </div>
      <div className={style.lastName}>
        <Field
          type="text"
          name={`${contactType}.lastName`}
          id={`${contactType}lastName`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              className={style.formFieldInput}
              label={lastName}
              error={!!form.errors[contactType]?.lastName}
              variant="outlined"
              {...field}
            />
          )}
        </Field>
        <ErrorMessage
          name={`${contactType}.lastName`}
          component="div"
          className={style.formFieldErrorMessage}
        />
      </div>
      <div className={style.address1}>
        <Field
          type="text"
          name={`${contactType}.address1`}
          id={`${contactType}address1`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              label={address1}
              error={!!form.errors[contactType]?.address1}
              variant="outlined"
              className={style.formFieldInput}
              {...field}
            />
          )}
        </Field>
      </div>
      <div className={style.address2}>
        <Field
          type="text"
          name={`${contactType}.address2`}
          id={`${contactType}address2`}
        >
          {({ field }: { field: { name: string, value: string } }) => (
            <TextField
              fullWidth
              className={style.formFieldInput}
              label={address2}
              variant="outlined"
              {...field}
            />
          )}
        </Field>
      </div>
      <div className={style.postalCode}>
        <Field
          type="text"
          name={`${contactType}.postalCode`}
          id={`${contactType}postalCode`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              label={postalCode}
              error={!!form.errors[contactType]?.postalCode}
              variant="outlined"
              className={style.formFieldInput}
              {...field}
            />
          )}
        </Field>
      </div>
      <div className={style.city}>
        <Field
          type="text"
          name={`${contactType}.city`}
          id={`${contactType}city`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              label={city}
              error={!!form.errors[contactType]?.city}
              variant="outlined"
              className={style.formFieldInput}
              {...field}
            />
          )}
        </Field>
      </div>
      <div className={style.country}>
        <Field name={`${contactType}.country`}>
          {({ field }: FieldProps) => (
            <FormControl fullWidth variant="outlined" className={style.formFieldInput}>
              <InputLabel htmlFor={`${contactType}country`}>{country}</InputLabel>
              <Select
                label={country}
                {...field}
                id={`${contactType}country`}
              >
                {COUNTRIES.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </div>
      <div className={style.email}>
        <Field
          type="email"
          name={`${contactType}.email`}
          id={`${contactType}email`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <TextField
              fullWidth
              label={email}
              className={style.formFieldInput}
              error={!!form.errors[contactType]?.email}
              variant="outlined"
              {...field}
            />
          )}
        </Field>
        <ErrorMessage
          name={`${contactType}.email`}
          component="div"
          className={style.formFieldErrorMessage}
        />
      </div>
    </div>
  );
};

export default CheckoutContactFields;
