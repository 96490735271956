import { Action, ActionType, VenueEventState } from './types';

const initialState: VenueEventState = {
  fetching: false,
  ticketSelection: {
    consecutiveSeats: 2,
  },
};

export const reducer = (
  state = initialState,
  action: Action,
): VenueEventState => {
  switch (action.type) {
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { venueEvent } = action.payload;
      return {
        ...state,
        error: undefined,
        fetching: false,
        venueEvent,
      };
    }
    case ActionType.SET_CONSECUTIVE_SEATS: {
      const consecutiveSeats = Math.max(action.payload.consecutiveSeats, 0);
      return {
        ...state,
        ticketSelection: {
          ...state.ticketSelection,
          consecutiveSeats,
        },
      };
    }
    default: {
      return state;
    }
  }
};
