import {
  ActionType,
  FetchComponent,
  FetchScope,
  StartAction,
  StopAction,
} from './';
import { v4 as createId } from 'uuid';

export const createFetchComponent = (
  scope = FetchScope.ALL,
): FetchComponent => ({
  id: createId(),
  scope,
});

export const createFetchActions = (
  component: FetchComponent = createFetchComponent(),
): { fetchStart: StartAction; fetchStop: StopAction } => ({
  fetchStart: { payload: component, type: ActionType.START },
  fetchStop: { payload: { id: component.id }, type: ActionType.STOP },
});
