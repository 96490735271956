import React from 'react';
import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { createFetchActions, FetchComponent } from '../Fetch';
import { ActionType, Mode, PlaceContract, PlacesReseatingStateAPIResponse } from './types';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';
import config from '../../config.json';
import { ensureBasicState } from '../TicketSelection/utils/ensureBasicState';
import { mapReseatingStateAPIResponse } from './mapReseatingStateAPIResponse';
import { SyncThunkAction } from '../thunkAction';
import { useLocale } from '../Localization';
import { toast } from 'react-toastify';

const useShowSuccessToast = (place: PlaceContract, index: number): void => {
  const { strings } = useLocale();

  const content = (
    <>
      <p>
        <strong>
          {index}. {place.seasonTicketLegalRecipient}
        </strong>
      </p>
      {strings.Shared_Block} {place.blockLabel}
      {place.blockType === 'seating' && (
        <>
          , {strings.Shared_Row} {place.rowLabel}, {strings.Shared_Seat}{' '}
          {place.seatLabel}
        </>
      )}
    </>
  );

  toast(content, {
    closeButton: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    progressStyle: { background: '#009e0f' },
  });
};

export const getPlacesReseating = (
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);

  try {
    const { purchaseForTicketHolderId, venueEventId } = ensureBasicState(
      getState(),
    );
    const endpoint = config.API_ENDPOINTS.GET_RESEATING_SEATS;
    const requestURL = generateURL(endpoint, {
      params: { venueEventId, salesChannelKey: 'reseating' },
      query: { purchaseForTicketHolderId },
    });

    const response = await apiGETRequest(requestURL) as PlacesReseatingStateAPIResponse;
    const state = mapReseatingStateAPIResponse(response);

    dispatch({ payload: { state }, type: ActionType.SET_RESEATING });
  } catch (error) {
    handleError(dispatch, error as Error);
  } finally {
    dispatch(fetchStop);
  }
};

export const processReseating = (placeId: string): SyncThunkAction => (
  dispatch,
  getState,
): void => {
  const appState = getState();

  const { venuePlaces, places } = appState.placesReseating;
  const venuePlace = venuePlaces.find((place) => place.place.id === placeId);
  const index = appState.placesReseating.selectedPlaces.length + 1;

  if (venuePlace) {
    let mode = Mode.View;
    let venueActionType = ActionType.DESELECT;
    let selectedActionType = ActionType.REMOVE_SELECTED_PLACE;

    if (venuePlace.status === 'reseating') {
      venueActionType = ActionType.SELECT;
      selectedActionType = ActionType.ADD_SELECTED_PLACE;
      mode = Mode.Selected;
    }

    dispatch({ payload: { placeId }, type: venueActionType });
    dispatch({
      payload: { placeId, mode },
      type: ActionType.TOGGLE_PLACE_MODE,
    });

    const place: PlaceContract | undefined = places.find(
      (place) => place.id === placeId,
    );

    if (place) {
      dispatch({
        payload: { placeId, reseatingContractId: place.contractId },
        type: selectedActionType,
      });

      if (venuePlace.status === 'reseating') {
        useShowSuccessToast(place, index);
      }
    }
  }
};
