import * as React from 'react';
import { Component, createRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { VenuePlanProps, VenueSeatData, VenuePlanSettings } from './data';
import * as PIXI from 'pixi.js';
import { Container, Assets, Graphics, Point, Sprite, Renderer, Texture } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import {
  SEAT_BY_ID,
  createSeatSprite,
  getSeatById,
  getSeatSpriteById,
  setSeatSpriteState as setSeatState,
} from './SeatSprite';
import {
  calculateBoundingBox,
  clamp,
  coordinatesInsideRectangle,
  distance,
  isInBounds,
  lerp,
} from './utils';
import { getNumericLabelTexture, getNumericLabelText } from './resources';
import { ViewportClampPlugin2 } from './ViewportClampPlugin2';
import { ViewportZoomToPointPlugin } from './ViewportZoomToPointPlugin';
import { easeQuad } from 'd3-ease';
import { SpatialHash } from 'pixi-cull';
import { PlaceGraphicalState } from './PlaceGraphicalState';
import { ease } from 'pixi-ease';
import { TINT_SEAT_UNAVAILABLE } from './colors';

import { getRootLogger } from '../../util/logging';

import {
  ACTIVE_BLOCKS_CIRCLE_SIZE,
  ANIMATION_DURATION_ENTER_MS,
  ANIMATION_DURATION_FLIGHT_TO_PLACE_MS,
  ANIMATION_DURATION_TAP_ZOOM_MS,
  AREA_LOD_BIG_BLOCKS_LABELS,
  AREA_LOD_ROW_LABELS,
  AREA_LOD_SEATS,
  AREA_LOD_SEAT_LABELS,
  AREA_LOD_SMALL_BLOCKS_LABELS,
  AREA_MAX_ZOOM,
  DEBUG_ACTIVE_BLOCKS_CIRCLE,
  LOD_BIG_BLOCKS_LABELS,
  LOD_BLOCKS,
  LOD_INIT,
  LOD_ROW_LABELS,
  LOD_SEATS,
  LOD_SEATS_LABELS,
  LOD_SMALL_BLOCKS_LABELS,
  MAX_DOUBLE_TAP_TIME_MS,
  MIN_LONG_PRESS_TIME_FOR_OVERLAY_MS,
  MIN_PANNING_DISTANCE_FOR_OVERLAY_PX,
  SINGLE_TAP_THROTTLE_MS,
  VIEWPORT_WORLD_PADDING
} from './constants';
import { Block, BlockReseatingStatus, createBlockGraphic } from './blocks';
import assert from 'assert';
import { InteractionTarget } from './interaction';
import { updateRowLabels } from './rowLabels';
import { debounce } from 'lodash';

const log = getRootLogger();

type SeatsAndLabelContainerByBlock = Map<
  string,
  { labels: Container; seats: Container }
>;

/**
 * NOTE: seats data is compared by reference
 */
export default class VenuePlan extends Component<VenuePlanProps> {
  private canvasContainerRef = createRef<HTMLDivElement>();
  private renderer?: Renderer;
  private viewport?: Viewport;
  private sceneRootContainer?: Container;
  private zoomToPointPlugin?: ViewportZoomToPointPlugin;
  private readonly stage = new PIXI.Container();
  private readonly seatsContainer = new PIXI.Container();
  private readonly imageSpriteContainer = new PIXI.Container();
  private readonly seatsLabelsContainer = new PIXI.Container();
  private readonly rowLabelsContainer = new PIXI.Container();
  private readonly cull = new SpatialHash({ size: 50 });
  private readonly ticker = new PIXI.Ticker();

  /** current state, true if we are zoomed to the seats level, false otherwise */
  private lod = LOD_INIT;

  private selectedSeatSprites = new Set<Sprite>();
  private blocksById = new Map<string, Block>();

  private resizeRequired = false;
  private pinching = false;

  private pointerDownStartPoint: Point | null = null;
  private pointerDownStartTime = Infinity;
  private farPanning = false;
  private longPressing = false;
  private prevClickTime = 0;

  private ctrlKeyPressed = false;
  private rectangleStart: Point | null = null;
  private rectangleStartSeats: Point | null = null;
  private currentRectangle: Graphics | null = null;

  private isLoaded = false;

  private invalid = false;

  private interactionTarget: InteractionTarget = { type: 'NONE' };

  private enterAnimationPerformed = false;

  constructor(props: VenuePlanProps) {
    super(props);

    this.cull.addContainer(this.seatsContainer);
    this.cull.addContainer(this.imageSpriteContainer);
    this.cull.addContainer(this.seatsLabelsContainer);
    this.cull.addContainer(this.rowLabelsContainer);

    this.ticker.add(() => {
      this.validateSize();
      // cull whenever the viewport moves
      const viewport = this.viewport;
      const renderer = this.renderer;
      if (renderer && viewport) {
        // handle finish of decelerate
        if (!viewport.dirty) {
          // check there was panning, but it is finished (no pointer down)
          if (this.farPanning && !this.pointerDownStartPoint) {
            this.farPanning = false;
          }
        }
        if (!this.zoomToPointPlugin?.isActive()) {
          if (viewport.dirty) {
            const visibleBounds = viewport.getVisibleBounds();
            this.cull.cull(visibleBounds, true);
            this.updateLevelOfDetails();
          }

          if (
            !this.longPressing &&
            this.ticker.lastTime - this.pointerDownStartTime >
              MIN_LONG_PRESS_TIME_FOR_OVERLAY_MS
          ) {
            this.longPressing = true;
            this.clearInteractionTarget();
          } else {
            if (this.hasInteractionTarget()) {
              const timeSinceTap = this.ticker.lastTime - this.prevClickTime;
              if (
                timeSinceTap > MAX_DOUBLE_TAP_TIME_MS &&
                timeSinceTap < SINGLE_TAP_THROTTLE_MS
              ) {
                this.handleSingleTap();
              }
            }
          }
        }

        const easing = ease.count > 0;

        if (this.invalid || viewport.dirty || easing) {
          // render
          renderer.render(this.stage);

          this.invalid = false;
          viewport.dirty = false;
        }
      }
    });
  }

  componentDidMount(): void {
    this.load();

    window.addEventListener('keydown', this.keydownHandler, false);
    window.addEventListener('keyup', this.keyupHandler, false);
  }

  componentWillUnmount(): void {
    this.ticker.stop();

    window.removeEventListener('keydown', this.keydownHandler);
    window.removeEventListener('keyup', this.keyupHandler);
  }

  componentDidUpdate(prevProps: Readonly<VenuePlanProps>): void {
    if (!this.isLoaded) return;
    
    if (prevProps.places.seats !== this.props.places.seats) {
      this.reloadSeatsFromProps();
      this.reloadImagesFromProps();
      this.refreshSeatAvailabilityStateFromProps();
      this.reloadSelectedSeatsFromProps();
      this.updateBlockReseatingStatus();
      this.invalid = true;
      // reset on every plan change
      this.enterAnimationPerformed = false;
    } else {
      if (
        prevProps.availability?.version !== this.props.availability?.version
      ) {
        this.refreshSeatAvailabilityStateFromProps();
        this.reloadImagesFromProps();
        this.invalid = true;
      }
      if (prevProps.placesSelection !== this.props.placesSelection) {
        this.reloadSelectedSeatsFromProps();
        this.invalid = true;
      }
      if (prevProps.placesReseating !== this.props.placesReseating) {
        this.updateBlockReseatingStatus();
      }
      if (prevProps.flightToPlace !== this.props.flightToPlace) {
        if (this.props.flightToPlace) {
          this.flightToPlace(this.props.flightToPlace);
        }
      }
    }

    this.updateSeatsAndBlocksInteractiveState();
    debounce(() => this.performRevealAnimationIfNecessary(), 100)();  //locally necessary else zoomPlug is not ready
  }

  render(): React.ReactNode {
    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={this.invalidateSize.bind(this)}
      >
        {/*
          Viewport should always fill the parent element. Inline styles because
           CSS module styles don't seem to work for some reason and globale
           stylesheet only for this component is awkward to use in combination
           with the rest of the frontend.
         */}
        <div
          style={{ height: '100%', width: '100%' }}
          ref={this.canvasContainerRef}
        />
      </ReactResizeDetector>
    );
  }

  private keydownHandler = (e: KeyboardEvent): void => {
    if (e.ctrlKey) {
      this.ctrlKeyPressed = true;
    }
  };

  private keyupHandler = (e: KeyboardEvent): void => {
    if (!e.ctrlKey) {
      this.ctrlKeyPressed = false;
    }
  };

  private async load() {
    // ref should always be defined during componentDidMount()
    const canvasContainer = this.canvasContainerRef.current!;

      // Detection of renderer could fail if WebGL is not supported.
      this.renderer = PIXI.autoDetectRenderer({
        width: canvasContainer.offsetWidth,
        height: canvasContainer.offsetHeight,
        antialias: true,
        autoDensity: true,
        background: 0xF0F0F0,
        powerPreference: 'high-performance',
        backgroundAlpha: 1,
        resolution: window.devicePixelRatio || 1
      }) as Renderer;

      this.viewport = new Viewport({
        screenWidth: canvasContainer.offsetWidth,
        screenHeight: canvasContainer.offsetHeight,
        worldWidth: 500,
        worldHeight: 500,
        passiveWheel: false,
        events: this.renderer.events
      });
      
      this.viewport.drag().pinch().wheel().decelerate({
        friction: 0.95,
        minSpeed: 0.2,
      });
      

      //TODO: fix PLUGINS
      this.zoomToPointPlugin = new ViewportZoomToPointPlugin(this.viewport);
      // @ts-ignore
      this.viewport.plugins.add('zoom-to-point', this.zoomToPointPlugin);

      this.sceneRootContainer = new Container();
      this.sceneRootContainer.name = 'scene_root';

      this.sceneRootContainer.addChild(
        this.imageSpriteContainer,
        this.rowLabelsContainer,
        this.seatsContainer,
        this.seatsLabelsContainer,
      );

      this.viewport.addChild(this.sceneRootContainer);

      this.setupViewportInteraction();

      this.stage.addChild(this.viewport);

      if (DEBUG_ACTIVE_BLOCKS_CIRCLE) {
        const radius =
          Math.min(canvasContainer.offsetWidth, canvasContainer.offsetHeight) *
          0.5 *
          ACTIVE_BLOCKS_CIRCLE_SIZE;
        const circleGraphics = new Graphics();
        circleGraphics.beginFill(0x1010ff, 0.2);
        circleGraphics.drawCircle(
          canvasContainer.offsetWidth * 0.5,
          canvasContainer.offsetHeight * 0.5,
          radius,
        );
        circleGraphics.endFill();
        this.stage.addChild(circleGraphics);
      }

      await this.reloadSeatsFromProps();
      await this.reloadImagesFromProps();
      this.setupVenuePlanDisplay();
      this.refreshSeatAvailabilityStateFromProps();
      this.reloadSelectedSeatsFromProps();
      this.updateBlockReseatingStatus();

      canvasContainer.appendChild(this.renderer.view as HTMLCanvasElement);

      this.ticker.start();
      this.isLoaded = true;
      this.props.onLoaded();

  }

  private setupViewportInteraction() {
    assert(this.viewport, 'viewport has to be initialized');

    this.viewport.on('pinch-start', () => {
      this.pinching = true;
      this.clearInteractionTarget();
    });
    this.viewport.on('pinch-end', () => {
      this.pinching = false;
    });
    this.viewport.on('clicked', (e) => {
      // detect double tap
      const timeAfterLastTap = this.ticker.lastTime - this.prevClickTime;
      this.prevClickTime = this.ticker.lastTime;
      if (this.lod === LOD_SEATS_LABELS && this.hasInteractionTarget()) {
        this.handleSingleTap();
      } else if (timeAfterLastTap < MAX_DOUBLE_TAP_TIME_MS) {
        this.clearInteractionTarget();
        this.handleDoubleTap(e.world);
      }
    });
    this.viewport.addListener('pointerdown', (e) => {
      if (this.ctrlKeyPressed) {
        if (this.viewport) {
          this.viewport.pause = true;
        }

        this.rectangleStart = e.data.global.clone();
        this.rectangleStartSeats = e.data
          .getLocalPosition(this.seatsContainer)
          .clone();

        if (this.currentRectangle) {
          this.currentRectangle.clear();
        } else {
          this.currentRectangle = new PIXI.Graphics();
          this.stage.addChild(this.currentRectangle);
        }

        if (this.viewport) {
          this.viewport.pause = false;
        }

        return;
      }

      this.rectangleStart = null;
      this.rectangleStartSeats = null;
      if (this.currentRectangle) {
        this.currentRectangle.clear();
      }

      this.pointerDownStartTime = this.ticker.lastTime;
      this.pointerDownStartPoint = e.data.global.clone();
    });
    this.viewport.addListener('pointermove', (e) => {
      if (this.rectangleStart !== null) {
        if (this.currentRectangle) {
          if (this.viewport) {
            this.viewport.pause = true;
          }

          const bb = calculateBoundingBox([this.rectangleStart, e.data.global]);

          this.currentRectangle.clear();
          this.currentRectangle.lineStyle(1, 0x000000);
          this.currentRectangle.beginFill(0x000000, 1 / 25);
          this.currentRectangle.drawRect(
            bb.xmin,
            bb.ymin,
            bb.xmax - bb.xmin,
            bb.ymax - bb.ymin,
          );

          if (this.viewport) {
            this.viewport.pause = false;
          }
        }

        return;
      }

      if (!this.farPanning && this.pointerDownStartPoint) {
        const pointerPosition = e.data.global;
        const dist = distance(pointerPosition, this.pointerDownStartPoint);
        this.farPanning = dist > MIN_PANNING_DISTANCE_FOR_OVERLAY_PX;
        if (this.farPanning) {
          this.clearInteractionTarget();
        }
      }
    });
    this.viewport.addListener('pointerup', (e) => {
      this.resetLongPress();
      if (this.rectangleStartSeats !== null && this.currentRectangle) {
        const startX = this.rectangleStartSeats?.x;
        const startY = this.rectangleStartSeats?.y;

        const endX = e.data.getLocalPosition(this.seatsContainer).x;
        const endY = e.data.getLocalPosition(this.seatsContainer).y;

        const selectedSeats: VenueSeatData[] = [];

        SEAT_BY_ID.forEach((seat) => {
          if (
            coordinatesInsideRectangle(
              startX,
              startY,
              endX,
              endY,
              seat.x,
              seat.y,
            )
          ) {
            selectedSeats.push(seat);
          }
        });

        if (selectedSeats.length > 0) {
          this.handleMultiSeatsSelect(selectedSeats);
        }

        this.currentRectangle.clear();
        this.rectangleStart = null;
        this.rectangleStartSeats = null;
      }
    });
    this.viewport.addListener('pointercancel', () => {
      this.resetLongPress();
    });
    this.viewport.addListener('pointerupoutside', () => {
      this.resetLongPress();
    });
  }

  private resetLongPress() {
    this.longPressing = false;
    this.pointerDownStartPoint = null;
    this.pointerDownStartTime = Infinity;
  }


  private invalidateSize() {
    this.resizeRequired = true;
  }

  private validateSize() {
    if (this.resizeRequired) {
      this.resizeRequired = false;
      const canvasContainer = this.canvasContainerRef.current;
      if (canvasContainer && this.renderer) {
        const newWidth = canvasContainer.offsetWidth;
        const newHeight = canvasContainer.offsetHeight;
        this.renderer.resize(newWidth, newHeight);
        if (this.viewport) {
          const bounds = this.viewport.getVisibleBounds();
          const centerX = bounds.x + bounds.width * 0.5;
          const centerY = bounds.y + bounds.height * 0.5;

          this.viewport.resize(newWidth, newHeight);
          this.viewport.moveCenter(centerX, centerY);

          this.updateViewportClamp();
        }
      }
    }
  }

  private updateLevelOfDetails() {
    const visibleBounds = this.viewport!.getVisibleBounds();
    const visibleArea = visibleBounds.width * visibleBounds.height;
    let newLod;
    if (visibleArea < AREA_LOD_SEAT_LABELS) {
      newLod = LOD_SEATS_LABELS;
    } else if (visibleArea < AREA_LOD_ROW_LABELS) {
      newLod = LOD_ROW_LABELS;
    } else if (visibleArea < AREA_LOD_SEATS) {
      newLod = LOD_SEATS;
    } else if (visibleArea < AREA_LOD_SMALL_BLOCKS_LABELS) {
      newLod = LOD_SMALL_BLOCKS_LABELS;
    } else if (visibleArea < AREA_LOD_BIG_BLOCKS_LABELS) {
      newLod = LOD_BIG_BLOCKS_LABELS;
    } else {
      newLod = LOD_BLOCKS;
    }
    if (this.lod !== newLod) {
      this.lod = newLod;
      this.seatsLabelsContainer.visible = this.lod >= LOD_SEATS_LABELS;
      this.updateSeatsAndBlocksInteractiveState();
    }
  }

  private updateSeatsAndBlocksInteractiveState() {
    this.seatsContainer.interactiveChildren =
      (this.props.isSelectionEnabled ?? true) && this.lod >= LOD_SEATS;
  }

  private onUserSeatPointerDown(seat: VenueSeatData) {
    this.interactionTarget = { type: 'SEAT', seat };
  }

  private setInteractionTarget(block: Block): void {
    this.interactionTarget = { type: 'BLOCK', block };
  }

  private hasInteractionTarget(): boolean {
    return this.interactionTarget.type !== 'NONE';
  }

  private clearInteractionTarget(): void {
    this.interactionTarget = { type: 'NONE' };
  }

  private handleSingleTapOnBlock(block: Block): void {
    this.props.onSelectionEvent?.({
      type: 'BLOCK',
      blockId: block.id,
      blockName: block.name,
    });
  }

  private handleSingleTapOnSeat(seat: VenueSeatData): void {
    switch (seat.state) {
      case PlaceGraphicalState.AVAILABLE:
      case PlaceGraphicalState.RESEATING_AVAILABLE:
        this.props.onSelectionEvent?.({
          type: 'SEAT',
          seatId: seat.id,
          selected: true,
        });
        break;
      case PlaceGraphicalState.SELECTED:
      case PlaceGraphicalState.PROCESSING:
        this.props.onSelectionEvent?.({
          type: 'SEAT',
          seatId: seat.id,
          selected: false,
        });
        break;
      case PlaceGraphicalState.RESEATING:
      case PlaceGraphicalState.RESEATING_SELECTED:
        this.props.onSelectionReseatingEvent?.({
          type: 'SEAT',
          seatId: seat.id,
          selected: false,
        });
        break;
    }
  }

  private handleMultiSeatsSelect(seats: VenueSeatData[]): void {
    this.props.onSelectionEvent?.({
      type: 'SEATS',
      seats: seats.map((seat) => {
        return { id: seat.id, state: seat.state };
      }),
    });
  }

  private handleSingleTap(): void {
    switch (this.interactionTarget.type) {
      case 'BLOCK':
        this.handleSingleTapOnBlock(this.interactionTarget.block);
        break;
      case 'SEAT':
        this.handleSingleTapOnSeat(this.interactionTarget.seat);
        break;
    }
    this.clearInteractionTarget();
  }

  /** Adds or removes selection from the sprite */
  private setSeatSpriteState(seat: VenueSeatData, state: PlaceGraphicalState) {
    setSeatState(seat, state);

    if (!seat.sprites) {
      return;
    }

    if (
      state === PlaceGraphicalState.SELECTED ||
      state === PlaceGraphicalState.PROCESSING
    ) {
      this.selectedSeatSprites.add(seat.sprites.seat);
    } else {
      this.selectedSeatSprites.delete(seat.sprites.seat);
    }
  }

  private setupVenuePlanDisplay() {
    // fit viewport to the layout
    const viewport = this.viewport!;
    const contentBounds: PIXI.Rectangle = this.sceneRootContainer!.getBounds();  //calc max x/y boundaries from center
    const boundsWidth = Math.max(-contentBounds.left, contentBounds.right) * 2;
    const boundsHeight = Math.max(-contentBounds.top, contentBounds.bottom) * 2;
    const screenToWorldRatio = Math.max(boundsWidth / viewport.screenWidth, boundsHeight / viewport.screenHeight);

    viewport.worldWidth = viewport.screenWidth * screenToWorldRatio + VIEWPORT_WORLD_PADDING;
    viewport.worldHeight = viewport.screenHeight * screenToWorldRatio + VIEWPORT_WORLD_PADDING;

    viewport.moveCenter(0, 0);
    viewport.fitWorld(true);

    this.updateViewportClamp();
    this.updateLevelOfDetails();
  }

  private async reloadSeatsFromProps() {
    if (this.props.places.seats.length === 0) {
      return;
    }

    log.debug('create seats - start');

    // TODO reuse seats sprites from previous plan
    this.selectedSeatSprites.clear();
    this.seatsContainer.removeChildren();
    this.seatsLabelsContainer.removeChildren();

    const seatsAndLabelContainersByBlock = await this.createSeatAndLabelSprites(
      this.props.places.seats,
    );

    for (const [, { seats, labels }] of seatsAndLabelContainersByBlock) {
      this.seatsContainer.addChild(seats);
      this.seatsLabelsContainer.addChild(labels);
    }

    const {
      showRowNumberAtBeginningOfRow,
      showRowNumberAtEndOfRow
    } = this.props.places.venuePlanSettings;
    
    const getRowLabelsType = (beginning: boolean, end: boolean) => {
      if (!beginning && !end) return 'NONE';
      if (beginning && !end) return 'START_ONLY';
      if (!beginning && end) return 'END_ONLY';
      return 'ALL';
    };
    
    const rowLabelsType = getRowLabelsType(showRowNumberAtBeginningOfRow, showRowNumberAtEndOfRow);
    updateRowLabels(this.props.places.seats, this.rowLabelsContainer, rowLabelsType);
  

    log.debug('create seats - end');
  }

  private async reloadImagesFromProps() {
    if (this.props.places.images.length === 0) {
      return;
    }

    this.imageSpriteContainer.removeChildren();

    // add images
    const getImages = this.props.places.images;
    for (const imageData of getImages) {
      try {
          const texture: Texture = await Assets.load(imageData.url);
          if (texture) {
              const imageSprite = Sprite.from(texture);
              imageSprite.anchor.set(0.5);
              imageSprite.width = imageData.width;
              imageSprite.height = imageData.height;
              imageSprite.angle = imageData.rotationDegree;
              imageSprite.position.set(imageData.posX, imageData.posY);
              imageSprite.zIndex = imageData.zIndex;
  
              this.imageSpriteContainer.addChild(imageSprite);
          } else {
              console.error(`Failed to load texture for image: ${imageData.url}`);
          }
      } catch (error) {
          console.error(`Error loading texture for image: ${imageData.url}`, error);
      }
    }
  }

  /**
   * Create sprites and labels for seats based on the provided data.
   *
   * @param seats
   */
  private async createSeatAndLabelSprites(
    seats: VenueSeatData[],
  ): Promise<SeatsAndLabelContainerByBlock> {
    const result = new Map<string, { labels: Container; seats: Container }>();

    for (const seat of seats) {
      const blockId = seat.blockId;
      const blockSeatsContainer = result.get(blockId) ?? {
        labels: new Container(),
        seats: new Container(),
      };
  
      result.set(blockId, blockSeatsContainer);

      // TODO: Using numeric seats with using Text instead of Sprite(Uncomment if needed)
      const seatLabelText = await getNumericLabelText(seat.seatLabel);
      // const seatLabelText = new Sprite(await getNumericLabelTexture(seat.seatLabel));

      seatLabelText.anchor.set(0.5);
      seatLabelText.position.set(seat.x, seat.y);

      const getSeatLabelLength = seat.seatLabel.length;
      
      seatLabelText.width = 0.24;
      seatLabelText.height = 0.4;
      
      if (getSeatLabelLength >= 2) {
        seatLabelText.width = 0.24 * (getSeatLabelLength - 0.4);
      }

      seatLabelText.eventMode = 'passive';
      blockSeatsContainer.labels.addChild(seatLabelText);
  
      const sprite = await createSeatSprite(seat);
      sprite.x = seat.x;
      sprite.y = seat.y;
      sprite.addListener('pointerdown', () => this.onUserSeatPointerDown(seat));
      sprite.tint = TINT_SEAT_UNAVAILABLE;
      blockSeatsContainer.seats.addChild(sprite);

      sprite.zIndex = 1000;
      seat.sprites = {
        label: seatLabelText,
        seat: sprite,
      };

      SEAT_BY_ID.set(seat.id, seat);
    }

    return result;
  }

  private handleDoubleTap(tapPoint: Point) {
    const lod = this.lod;
    let targetArea;
    if (lod <= LOD_BLOCKS) {
      targetArea = AREA_LOD_SMALL_BLOCKS_LABELS;
    } else if (lod < LOD_SEATS || lod >= LOD_SEATS_LABELS) {
      targetArea = lerp(AREA_LOD_SEAT_LABELS, AREA_LOD_ROW_LABELS, 0.99);
    } else {
      targetArea = AREA_MAX_ZOOM;
    }
    this.zoomToPointPlugin?.zoomToPoint({
      targets: [
        {
          ...this.getZoomDimensionsForPoint(tapPoint.x, tapPoint.y, targetArea),
          duration: ANIMATION_DURATION_TAP_ZOOM_MS,
          ease: easeQuad,
        },
      ],
    });
  }

  private getZoomDimensionsForPoint(
    targetX: number,
    targetY: number,
    targetArea: number,
  ): { centerX: number; centerY: number; width: number; height: number } {
    const viewport = this.viewport!;
    const screenRatio = viewport.screenWidth / viewport.screenHeight;
    const targetHeight = Math.sqrt(targetArea / screenRatio);
    const targetWidth = targetArea / targetHeight;

    // clamp target point with end zoom respect
    return {
      centerX: clamp(
        targetX,
        -viewport.worldWidth * 0.5 + targetWidth * 0.5,
        viewport.worldWidth * 0.5 - targetWidth * 0.5,
      ),
      centerY: clamp(
        targetY,
        -viewport.worldHeight * 0.5 + targetHeight * 0.5,
        viewport.worldHeight * 0.5 - targetHeight * 0.5,
      ),
      width: targetWidth,
      height: targetHeight,
    };
  }

  private getPlanBounds(): {
    centerX: number;
    centerY: number;
    height: number;
    width: number;
  } {
    // const blocks = this.props.layout.blocks;

    let minX: number | undefined = undefined;
    let minY: number | undefined = undefined;
    let maxX: number | undefined = undefined;
    let maxY: number | undefined = undefined;
    let centerX: number | undefined = undefined;
    let centerY: number | undefined = undefined;

    // TODO add support for selected standing places
    if (this.props.placesSelection.seats.length > 0) {
      this.props.placesSelection.seats.forEach((selectedSeat) => {
        const seat = this.props.places.seats.find((s) => s.id === selectedSeat.place.id);
        if (seat) {
          maxX = maxX ? Math.max(maxX, seat.x) : seat.x;
          minX = minX ? Math.min(minX, seat.x) : seat.x;
          maxY = maxY ? Math.max(maxY, seat.y) : seat.y;
          minY = minY ? Math.min(minY, seat.y) : seat.y;
        }
      });
    } else {
      this.props.places.seats.forEach((seat) => {
        maxX = maxX ? Math.max(maxX, seat.x) : seat.x;
        minX = minX ? Math.min(minX, seat.x) : seat.x;
        maxY = maxY ? Math.max(maxY, seat.y) : seat.y;
        minY = minY ? Math.min(minY, seat.y) : seat.y;
      });
    }

    maxX = maxX || 0;
    maxY = maxY || 0;
    minX = minX || 0;
    minY = minY || 0;
    centerX = (maxX + minX) / 2;
    centerY = (minY + maxY) / 2;

    return {
      centerX: centerX,
      centerY: centerY,
      height: maxY - minY + 10,
      width: maxX - minX + 10,
    };
  }

  private performRevealAnimationIfNecessary() {
    if (
      !this.enterAnimationPerformed &&
      this.props.isVisible &&
      !this.props.isShowLoader
    ) {
      log.debug('start animation');
      this.enterAnimationPerformed = true;
      const viewport = this.viewport!;
      let centerX = viewport.center.x;
      let centerY = viewport.center.y;
      let height = viewport.worldHeight;
      let width = viewport.worldWidth;

      const planBounds = this.getPlanBounds();
      if (planBounds.centerX || planBounds.centerY || planBounds.height || planBounds.width) {
        centerX = planBounds.centerX;
        centerY = planBounds.centerY;
        height = planBounds.height;
        width = planBounds.width;
      }

      const scale = Math.min(
        viewport.screenWidth / width,
        viewport.screenHeight / height,
      );
      const maxWidth = viewport.screenWidth / scale;
      const maxHeight = viewport.screenHeight / scale;

      this.zoomToPointPlugin?.zoomToPoint({
        targets: [
          {
            centerX: centerX,
            centerY: centerY,
            width: maxWidth,
            height: maxHeight,
            duration: ANIMATION_DURATION_ENTER_MS,
            ease: easeQuad,
          },
        ],
        interruptable: false,
      });
    }
  }

  private updateViewportClamp() {
    const viewport = this.viewport!;
    const scale = Math.min(
      viewport.screenWidth / viewport.worldWidth,
      viewport.screenHeight / viewport.worldHeight,
    );
    const maxWidth = viewport.screenWidth / scale;
    const maxHeight = viewport.screenHeight / scale;

    viewport.clampZoom({
      minWidth: Math.sqrt(AREA_MAX_ZOOM),
      minHeight: Math.sqrt(AREA_MAX_ZOOM),
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    });

    // use own implementation of clamp
    viewport.plugins.add(
      'clamp',
      new ViewportClampPlugin2(viewport, {
        left: -maxWidth * 0.5,
        right: maxWidth * 0.5,
        top: -maxHeight * 0.5,
        bottom: maxHeight * 0.5
      }),
    );
  }

  private refreshSeatAvailabilityStateFromProps() {
    const availability = this.props.availability;
    if (availability) {
      this.blocksById.forEach((block) => {
        block.status = availability.isBlockAvailable(block.id)
          ? 'AVAILABLE'
          : 'UNAVAILABLE';
      });

      SEAT_BY_ID.forEach((seat, seatId) => {
        switch (seat.state) {
          case PlaceGraphicalState.PROCESSING:
          case PlaceGraphicalState.SELECTED:
            return; // noop, don't change appearance of seat
          default:
            return this.setSeatSpriteState(
              seat,
              availability.isSeatAvailable(seatId)
                ? this.props.salesChannel === 'reseating'
                  ? PlaceGraphicalState.RESEATING_AVAILABLE
                  : PlaceGraphicalState.AVAILABLE
                : PlaceGraphicalState.UNAVAILABLE,
            );
        }
      });
    }
  }

  /** Performs one way sync of selected seats: props -> visual */
  private reloadSelectedSeatsFromProps() {
    const { placesSelection, availability, placesReseating } = this.props;

    // visually mark selected seats
    const newlySelectedSeatIds = new Set<string>();
    placesSelection.seats
      .concat(placesReseating.venuePlaces)
      .forEach((selectedPlace) => {
        if (selectedPlace.place.type === 'seat') {
          const seat = getSeatById(selectedPlace.place.id);
          if (seat) {
            let newState;
            switch (selectedPlace.status) {
              case 'selected':
                newState = PlaceGraphicalState.SELECTED;
                break;
              case 'processing':
                newState = PlaceGraphicalState.PROCESSING;
                break;
              case 'reseating':
                newState = PlaceGraphicalState.RESEATING;
                break;
              case 'reseating-selected':
                newState = PlaceGraphicalState.RESEATING_SELECTED;
                break;
              case 'reseating-completed':
                newState = PlaceGraphicalState.RESEATING_COMPLETED;
                break;
            }

            if (seat.state !== newState) {
              this.setSeatSpriteState(seat, newState);
            }
          }
          newlySelectedSeatIds.add(selectedPlace.place.id);
        }
      });

    // visually unmark unselected seats
    this.selectedSeatSprites.forEach((sprite: Sprite) => {
      if (sprite.name !== null) { // Check if the name is not null
        const seat = getSeatById(sprite.name);
        if (seat) {
          if (!newlySelectedSeatIds.has(seat.id)) {
            const newState = (
              availability ? availability.isSeatAvailable(seat.id) : true
            )
              ? this.props.salesChannel === 'reseating'
                ? PlaceGraphicalState.RESEATING_AVAILABLE
                : PlaceGraphicalState.AVAILABLE
              : PlaceGraphicalState.UNAVAILABLE;
  
            this.setSeatSpriteState(seat, newState);
          }
        }
      } 
    });

    this.blocksById.forEach((block) => {
      block.selectedAmount = placesSelection.standingPlaces[block.id] ?? 0;
    });
  }

  private updateBlockReseatingStatus(): void {
    const { salesChannel, placesReseating } = this.props;

    if (salesChannel !== 'reseating') return;

    this.blocksById.forEach((block) => {
      if (block.type === 'standing') {
        const placesInBlock = placesReseating.places
          .filter((place) => place.blockId === block.id)
          .map((place) => place.id);

        const selectedPlaces = placesReseating.venuePlaces.filter(
          (venuePlace) =>
            placesInBlock.includes(venuePlace.place.id) &&
            venuePlace.status === 'reseating-selected',
        );

        const completedPlaces = placesReseating.venuePlaces.filter(
          (venuePlace) =>
            placesInBlock.includes(venuePlace.place.id) &&
            venuePlace.status === 'reseating-completed',
        );

        const amount = placesInBlock.length - completedPlaces.length;
        let status: BlockReseatingStatus = 'AVAILABLE';

        switch (true) {
          case !!selectedPlaces.length:
            status = 'SELECTED';
            break;
          case !!completedPlaces.length:
            status = 'COMPLETED';
            break;
          case !!placesInBlock.length:
            status = 'AVAILABLE';
            break;
          default:
            status = 'UNAVAILABLE';
        }

        block.reseatingStatus = {
          amount: amount,
          status: status,
        };
      }
    });
  }

  private flightToPlace(place: { seatId: string }) {
    const seatSprite = getSeatSpriteById(place.seatId);
    if (seatSprite && this.zoomToPointPlugin) {
      const viewport = this.viewport!;
      const visibleBounds = viewport.getVisibleBounds();
      // keep at least current zoom
      const targetArea = Math.min(
        lerp(AREA_MAX_ZOOM, AREA_LOD_SEAT_LABELS, 0.5),
        visibleBounds.width * visibleBounds.height,
      );
      if (isInBounds(visibleBounds, seatSprite)) {
        // zoom in / move to the place directly
        this.zoomToPointPlugin.zoomToPoint({
          targets: [
            {
              ...this.getZoomDimensionsForPoint(
                seatSprite.x,
                seatSprite.y,
                targetArea,
              ),
              duration: ANIMATION_DURATION_FLIGHT_TO_PLACE_MS * 0.5,
              ease: easeQuad,
            },
          ],
        });
      } else {
        // first zoom out enough to fit current viewport and target point
        const firstWidth =
          Math.max(
            Math.abs(seatSprite.x - visibleBounds.x),
            Math.abs(seatSprite.x - (visibleBounds.x + visibleBounds.width)),
          ) * 1.5;
        const firstHeight =
          Math.max(
            Math.abs(seatSprite.y - visibleBounds.y),
            Math.abs(seatSprite.y - (visibleBounds.y + visibleBounds.height)),
          ) * 1.5;

        const scaleChange = Math.max(
          firstWidth / visibleBounds.width,
          firstHeight / visibleBounds.height,
        );
        log.debug('scale change: ', scaleChange);

        // calc duration based on scale change (zoom distance)
        const firstDuration = clamp(scaleChange / 25, 0, 1);
        this.zoomToPointPlugin.zoomToPoint({
          targets: [
            {
              centerX: (viewport.center.x + seatSprite.x) * 0.5,
              centerY: (viewport.center.y + seatSprite.y) * 0.5,
              width: firstWidth,
              height: firstHeight,
              duration: lerp(
                ANIMATION_DURATION_FLIGHT_TO_PLACE_MS * 0.25,
                ANIMATION_DURATION_FLIGHT_TO_PLACE_MS,
                firstDuration,
              ),
              ease: easeQuad,
            },
            // then zoom in / move to the place
            {
              ...this.getZoomDimensionsForPoint(
                seatSprite.x,
                seatSprite.y,
                targetArea,
              ),
              duration: ANIMATION_DURATION_FLIGHT_TO_PLACE_MS * 0.5,
              ease: easeQuad,
            },
          ],
        });
      }
    }
  }
}
