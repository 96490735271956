import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getTenantSlugFromURL } from '../../util/apiRequest';

const Default: React.FC = () => {
  const history = useHistory();
  const { tenantSlug } = useRouteMatch<{
    tenantSlug: string;
  }>().params;

  useEffect(() => {
    const getTenantSlug = getTenantSlugFromURL();
    history.push(`/${getTenantSlug}/events?sales_channel=web`);
  }, [history, tenantSlug]);

  return <></>;
};

export default Default;