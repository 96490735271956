import { PricingCategory } from '../TicketSelection/types/PricingCategory';
import { PricingClass } from '../TicketSelection/types/PricingClass';
import {
  Mode,
  Place,
  PlacesReseating,
  PlacesReseatingStateAPIResponse,
} from './types';
import { VenueSelectedPlace } from '../../components/VenuePlan/data';

export const mapReseatingStateAPIResponse = (
  response: PlacesReseatingStateAPIResponse,
): PlacesReseating => {
  const venuePlaces: VenueSelectedPlace[] = [];

  const places: Place[] = response.places.map((place) => {
    venuePlaces.push({
      place: {
        id: place.id,
        contractId: place.contractId,
        type: place.blockType === 'standing' ? 'block' : 'seat',
      },
      status: 'reseating',
    });

    const pricingCategory = response.pricingCategories.find(
      (category) => category.id === place.pricingCategoryId,
    );
    const pricingClass = response.pricingClasses.find(
      (pricingClass) =>
        pricingClass.id === place.selectedPurchasableItem.pricingClassId,
    );

    return {
      id: place.id,
      blockId: place.blockId,
      blockLabel: place.blockLabel,
      blockType: place.blockType,
      seatId: place.seatId,
      rowLabel: place.rowLabel,
      seatLabel: place.seatLabel,
      contractId: place.contractId,
      pricingCategoryId: place.pricingCategoryId,
      pricingCategoryName: pricingCategory?.name,
      pricingClassId: place.pricingCategoryId,
      pricingClassName: pricingClass?.publicName,
      selectedPurchasableItem: place.selectedPurchasableItem,
      seasonTicketLegalRecipient: place.seasonTicketLegalRecipient,
      grossAmount: place.selectedPurchasableItem?.grossAmount,
      mode: Mode.View,
    };
  });

  return {
    places,
    pricingCategories: response.pricingCategories as PricingCategory[],
    pricingClasses: response.pricingClasses as PricingClass[],
    venuePlaces,
    selectedPlaces: [],
  };
};
