import React from 'react';
import style from './style.module.css';

const CevronComponen: React.FC<{ direction: 'up' | 'down' }> = ({
  direction,
}) => {
  const className = `${style.Chevron} ${direction === 'up' ? style.Up : ''}`;
  return (
    <svg className={className} viewBox="0 0 8 5">
      <polyline points="1 1, 4 4, 7 1" stroke="red" fill="none" />
    </svg>
  );
};

export default CevronComponen;
