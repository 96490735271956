import { ActionType, FetchResponse, FormCheckoutContactData } from './index';
import config from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { History } from 'history';
import {
  apiDELETERequest,
  apiGETRequest,
  apiPOSTRequest,
  generateURL,
} from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import { FetchComponent, createFetchActions } from '../Fetch';
import { Dispatch } from 'redux';

export const getCheckout = (): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_CHECKOUT, {});

    const response = (await apiGETRequest(requestURL)) as FetchResponse;

    dispatch({ payload: { response }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};


export const postCheckoutContactData = (
  checkoutContactData: FormCheckoutContactData,
  history: History,
  tenantSlug: string,
): AsyncReduxThunkAction => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });
    const requestURL = generateURL(config.API_ENDPOINTS.POST_CHECKOUT_DATA, {});
    const body = {...checkoutContactData };
    const response = (await apiPOSTRequest(requestURL, body)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.POST_SUCCESS });
    history.push({ pathname: `/${tenantSlug}/checkout/${response.id}/${response.uid}/payment` });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const getCheckoutFromOrderId = (
  id: string,
  uid: string,
): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_CHECKOUT_ID, {
      params: { id, uid },
    });

    const response = (await apiGETRequest(requestURL)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const removeCartItem = (
  cartItemId: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);

  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.CHECKOUT_CART_ITEM, {
      params: { cartItemId },
    });

    const response = (await apiDELETERequest(requestURL)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
