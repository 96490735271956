
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2cae8d',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1d1d1f',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: 0,
          '&.Mui-disabled': {
            background: '#f6f6f6',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          background: '#f6f6f6',
          fontSize: '1.07rem',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 0,
          margin: '0 0 3px',
          padding: '0 6px',
          background: '#f6f6f6',
          boxShadow: 'none',
          fontSize: '1.07rem',
          fontWeight: '700',
        },
        content: {
          display: 'block',
          margin: '6px 0',
          padding: '0 6px 0 0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          fontSize: '.875rem',
        },
        sizeMedium: {
          fontSize: '1.07rem',
        },
        sizeLarge: {
          padding: '.9rem 1.5rem',
          fontSize: '1.07rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        sizeSmall: {
          fontSize: '.875rem',
        },
        sizeMedium: {
          fontSize: '1.07rem',
        },
        sizeLarge: {
          fontSize: '1.07rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(217,217,217,0.5)',
          backdropFilter: 'blur(2.5px)',
        },
      },
    },
  },
});