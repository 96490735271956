import { Action, ActionType, PurchasableItemsState } from './types';

export const reducer = (
  state: PurchasableItemsState = null,
  action: Action,
): PurchasableItemsState => {
  switch (action.type) {
    case ActionType.SET_PURCHASABLE_ITEMS: {
      return action.payload.state;
    }
    default: {
      return state;
    }
  }
};
