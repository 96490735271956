import React from 'react';
import { useLocale } from '../../state/Localization';
import { Place } from '../../state/TicketSelection';

import style from './style.module.css';

const sumTickets = (places: Iterable<Place>): number => {
  let sum = 0;
  for (const place of places) {
    if (place.selectedPurchasableItem && new Date() < place.expiresAt) {
      sum += place.selectedPurchasableItem.grossAmount;
    }
  }
  return sum / 100;
};

interface TicketCounterComponentProps {
  places: Place[];
}

const TicketCounterComponent: React.FC<TicketCounterComponentProps> = (props) => {
  const { language, strings } = useLocale();
  const sum = sumTickets(props.places.values());

  return (
    <div className={style.TicketCounter}>

      <div className={style.TicketIcon}>
        <img src={`${process.env.PUBLIC_URL}/icons/receipt.svg`} />
        {!!props.places.length &&
          <div className={style.Badge}>
            {props.places.length}
          </div>
        }
      </div>

      {props.places.length
        ? sum.toLocaleString(language, {
          currency: 'EUR',
          style: 'currency',
        })
        : <div>{strings.SubmitBasket_Tickets}</div>
      }
    </div>
  );
};

export default TicketCounterComponent;
