import {
  PricingCategory,
  PricingCategoryFlat,
} from '../TicketSelection/types/PricingCategory';
import {
  PricingClass,
  PricingClassFlat,
} from '../TicketSelection/types/PricingClass';
import { VenueSelectedPlace } from '../../components/VenuePlan/data';
import { PurchasableItemSelectedFlat } from '../TicketSelection/types/PurchasableItem';

export enum ActionType {
  SET_RESEATING = 'PLACES_RESEATING::SET_RESEATING',
  SELECT = 'PLACES_RESEATING::SELECT',
  DESELECT = 'PLACES_RESEATING::DESELECT',
  COMPLETE = 'PLACES_RESEATING::COMPLETE',
  TOGGLE_PLACE_MODE = 'PLACES_RESEATING::TOGGLE_PLACE_MODE',
  ADD_SELECTED_PLACE = 'PLACES_RESEATING::ADD_SELECTED_PLACE',
  REMOVE_SELECTED_PLACE = 'PLACES_RESEATING::REMOVE_SELECTED_PLACE',
}

export type PlacesReseatingState = PlacesReseating;

export interface PlacesReseating {
  places: Place[];
  pricingCategories: PricingCategory[];
  pricingClasses: PricingClass[];
  venuePlaces: VenueSelectedPlace[];
  selectedPlaces: SelectedPlace[];
}

export interface PlacesReseatingStateAPIResponse {
  places: PlaceContractFlat[];
  pricingCategories: PricingCategoryFlat[];
  pricingClasses: PricingClassFlat[];
}

export enum Mode {
  View = 'view',
  Selected = 'selected',
  Completed = 'completed',
}

export interface PlaceContract {
  id: string;
  blockId: string;
  blockLabel: string;
  blockType: string;
  seatId: string;
  rowLabel: string;
  seatLabel: string;
  contractId: string;
  pricingCategoryId: string;
  pricingCategoryName: string | undefined;
  pricingClassId: string;
  pricingClassName: string | undefined;
  seasonTicketLegalRecipient: string;
  grossAmount: number;
  mode: Mode;
}

export interface SelectedPlace {
  placeId: string;
  reseatingContractId: string;
}

interface PlaceContractFlat extends PlaceContract {
  selectedPurchasableItem: PurchasableItemSelectedFlat;
}

interface SetPlacesReseating {
  payload: { state: PlacesReseating };
  type: typeof ActionType.SET_RESEATING;
}

interface SelectAction {
  type: typeof ActionType.SELECT;
  payload: { placeId: string };
}

interface DeselectAction {
  type: typeof ActionType.DESELECT;
  payload: { placeId: string };
}

interface CompleteAction {
  type: typeof ActionType.COMPLETE;
  payload: { placeId: string };
}

interface ToggleModeAction {
  type: typeof ActionType.TOGGLE_PLACE_MODE;
  payload: { placeId: string; mode: Mode };
}

interface AddSelectedPlaceAction {
  type: typeof ActionType.ADD_SELECTED_PLACE;
  payload: SelectedPlace;
  options?: { unshift?: boolean };
}

interface RemoveSelectedPlaceAction {
  type: typeof ActionType.REMOVE_SELECTED_PLACE;
  payload: { placeId: string };
}

export type Place = PlaceContractFlat;

export type Action =
  | SetPlacesReseating
  | SelectAction
  | DeselectAction
  | CompleteAction
  | ToggleModeAction
  | AddSelectedPlaceAction
  | RemoveSelectedPlaceAction;
