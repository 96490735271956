import { ActionType, FetchResponse } from './index';
import config from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import {
  apiGETRequest,
  generateURL,
} from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';

export const getPaymentMethods = (): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_PAYMENT_METHODS, {});

    const response = (await apiGETRequest(requestURL)) as FetchResponse;
    dispatch({ payload: { response: { data: response } }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};
