import { BitmapText, Container, IPointData, Point } from 'pixi.js';
import '@pixi/math-extras';
import { VenueSeatData } from '.';
//import {difference, length, scale, sum} from '../../geometry';
import { LABEL_BITMAP_FONT, sanitizeStringForLabelUse } from './fonts';

type RowLabelType =
    | 'START'
    | 'END'

export type RowLabelVisibilityMode =
    | 'NONE'
    | 'ALL'
    | 'START_ONLY'
    | 'END_ONLY'


const SEAT_SIZE = 1;  //TODO: rely on a constant of the codebase
const ROW_LABEL_DEFAULT_DISTANCE = SEAT_SIZE * 1.15;
const LABEL_DEFAULT_COLOR = 0x4d4d4d;



/**
 * Die Reihen-Labels aktualisieren.
 */
export function updateRowLabels(seats: Iterable<VenueSeatData>, rowLabelsContainer: Container,
    mode: RowLabelVisibilityMode): void
{
    // Die simpelste Methode um Veränderungen abzubilden ist alles wegschmeißen und neu erzeugen.
    // Wenn die bisherigen Labels nicht entfernt würden, dann würden die alten und neuen Labels
    // gleichzeitig angezeigt. Bei einer Verschiebung bspw. am alten und am neuen Ort.
    // Eine effizientere aber auch komplexere Lösung wäre bspw. etwas wie object-pooling zu
    // betreiben und bestehende labels wiederzuverwenden.
    rowLabelsContainer.removeChildren();

    if (mode === 'NONE') {
        return;
    }

    const labelPositions = identifySeatRows(seats).mapValues((seats) => {
        return calculateRowLablePositions(seats, ROW_LABEL_DEFAULT_DISTANCE);
    });

    for (const [[, row], positions] of labelPositions) {
        // Für Reihen ohne Bezeichnung brauchen wir keine Labels zu erzeugen
        if (row) {
            rowLabelsContainer.addChild(...createRowLabels(row, positions, mode));
        }
    }
}


function createRowLabels(row: string, positions: RowLabelPositions, mode: RowLabelVisibilityMode): BitmapText[] {
    const result = [];

    if (mode === 'ALL' || mode === 'START_ONLY') {
        const startLabel = createRowLabel(row, positions.start);
        // Start-Label "rechtsbündig" ausrichten
        startLabel.anchor.set(0.5, 0.5);
        startLabel.eventMode = 'passive';
        result.push(startLabel);
    }

    if (mode === 'ALL' || mode === 'END_ONLY') {
        const endLabel = createRowLabel(row, positions.end);
        // End-Label "linksbündig" ausrichten
        endLabel.anchor.set(0.5, 0.5);
        endLabel.eventMode = 'passive';
        result.push(endLabel);
    }

    return result;
}

function createRowLabel(text: string, position: Point): BitmapText {
    const label = new BitmapText(sanitizeStringForLabelUse(text), {
        fontSize: SEAT_SIZE,
        fontName: LABEL_BITMAP_FONT,
        tint: LABEL_DEFAULT_COLOR,
        align: 'center'
    });

    label.position.copyFrom(position);
    label.eventMode = 'passive';

    return label;
}



/**
 * Repräsentiert die Positionen der Labels einer Reihe von Sitzplätzen.
 */
export interface RowLabelPositions {
    /**
     *  Position des Labels am Anfang der Reihe.
     */
    start: Point;

    /**
     *  Position des Labels am Ende der Reihe.
     */
    end: Point;
}

/**
 * Eine Map um Einträge unter einem (Bereich × Reihe) Schlüssel zu verwalten.
 *
 * Diese Klasse orientiert sich vom Interface her an der JS nativen Map-Klasse.
 */
export class SeatRowMap<V> {

    // Eine Map<[string, string], E> ist nicht gangbar, da Tupel technisch auch nur Arrays sind und daher
    // nicht sinnvoll als Schlüssel genutzt werden können, da nur das selbe Array unter "===" Semantik
    // als gleich angesehen wird. Einfach Bereich und Reihe mit Trennzeichen zu konkatenieren und dies als
    // Schlüssel zu verwenden ist auch nicht 100% robust, da diese Werte vom Benutzer eingegeben werden
    // und daher alle möglichen Zeichen inklusive der Trennzeichen enthalten könnten.
    //
    // Stand 2022-06-22 war keine library zu finden, die multi-key Maps für den hier vorliegende Anwendungsfall
    // sinnvoll abgebildet hätte, daher der Eigenbau.

    private entries: Map<string, Map<string, V>> = new Map<string, Map<string, V>>();

    /**
     * Prüft, ob ein bestimmter Eintrag in der map vorhanden ist.
     *
     * @param area Der Bereich unter dem der Wert eingetragen ist.
     * @param row Die Reihen unter der der Wert eingetragen ist.
     *
     * @return true wenn ein entsprechender Eintrag vorhanden ist.
     */
    has(blockId: string, row: string): boolean {
        return this.entries.get(blockId)?.has(row) ?? false;
    }

    /**
     * Liefert einen Wert aus der Map falls vorhanden.
     *
     * @param area Der Bereich unter dem der Wert eingetragen ist.
     * @param row Die Reihen unter der der Wert eingetragen ist.
     *
     * @return Der gesuchte Werte wenn vorhanden, andernfalls undefined.
     */
    get(blockId: string, row: string): V | undefined {
        return this.entries.get(blockId)?.get(row);
    }

    /**
     * Fügt einen Wert zur Map hinzu.
     *
     * @param area Der Bereich unter dem der Wert hinzugefügt werden soll.
     * @param row Die Reihe unter der der Wert hinzugefügt werden soll.
     * @param value Der Wert der hinzugefügt werden soll.
     *
     * @return Die Map selbst.
     */
    set(blockId: string, row: string, value: V): SeatRowMap<V> {
        const blockEntries = this.entries.get(blockId) ?? new Map<string, V>();
        blockEntries.set(row, value);

        this.entries.set(blockId, blockEntries);

        return this;
    }

    /**
     * Iterator über die Einträge der Map.
     *
     * Die Elemente des Iterator sind ein Tupel bestehend aus dem (Bereich × Reihe) Schlüssel und assoziiertem Wert.
     */
    * [Symbol.iterator](): Iterator<[[string, string], V]> {
        for (const [blockId, blockEntries] of this.entries) {
            for (const [row, v] of blockEntries) {
                yield [[blockId, row], v];
            }
        }
    }

    /**
     * Liefert ein Iterable über die Werte der Map.
     */
    values(): Iterable<V> {
        // Da generator functions nicht als arrow-functions deklariert werden können, haben diese eine eigene
        // this-Referenz, deshalb vorher die notwendige Referenz auslagern, solange diese noch im Scope ist.
        const entries = this.entries;

        return {
            [Symbol.iterator]: function* () {
                for (const [, blockEntries] of entries) {
                    for (const [, v] of blockEntries) {
                        yield v;
                    }
                }
            }
        };
    }

    /**
     * Liefert eine neue Map mit den gleichen Schlüsseln wie diese Map,
     * wobei alle Werte durch die callbackFn transformiert wurden.
     *
     * @param callbackFn function die auf die Werte angewendet wird, um diese zu transformieren.
     */
    mapValues<R>(callbackFn: (v: V) => R): SeatRowMap<R> {
        const result = new SeatRowMap<R>();

        for (const [blockId, blockEntries] of this.entries) {
            for (const [row, v] of blockEntries) {
                result.set(blockId, row, callbackFn(v));
            }
        }

        return result;
    }

    // fehlende Methoden werden nach Bedarf implementiert.
}

export function convert2RowLabelVisibilityMode(showAtRowStart: boolean, showAtRowEnd: boolean): RowLabelVisibilityMode {
    return(showAtRowStart
        ? (showAtRowEnd ? 'ALL' : 'START_ONLY')
        : (showAtRowEnd ? 'END_ONLY' : 'NONE')
    );
}

export function convert2EachRowLabelsVisibility(mode: RowLabelVisibilityMode): {showAtRowStart: boolean,
    showAtRowEnd: boolean}
{
    const showAtStart = ['START_ONLY', 'ALL'].includes(mode);
    const showAtEnd = ['END_ONLY', 'ALL'].includes(mode);
    return {showAtRowStart: showAtStart, showAtRowEnd: showAtEnd};
}

/**
 * Ermittelt die Reihen von Sitzplätze für eine Menge von Sitzplätzen.
 *
 * Sitzplätze ohne area werden einer area dem leeren string als label zugeordnet.
 *
 * @param seats Die Sitzplätze, die auf Reihen aufgeteilt werden sollen.
 *
 * @return Die identifizierten Reihen.
 */
export function identifySeatRows(seats: Iterable<VenueSeatData>): SeatRowMap<VenueSeatData[]> {
    const result: SeatRowMap<VenueSeatData[]> = new SeatRowMap<VenueSeatData[]>();

    for (const seat of seats) {
        const blockId = seat.blockId ?? '';
        const row = seat.rowLabel ?? '';
        const seatRow = result.get(blockId, row) ?? [];

        seatRow.push(seat);

        result.set(blockId, row, seatRow);
    }

    return result;
}

/**
 * Berechnet die Positionen der Labels einer Reihe von Sitzplätzen.
 *
 * Für die Berechnung der Positionen werden die Plätze am Anfang und Ende der Reihe als Referenz verwendet.
 *
 * @param seatPositions Eine Menge von Positionen von Sitzplätze, die eine Reihen darstellen.
 * @param labelDistance Der Abstand, den die Labels von den Referenzplätzen haben sollen.
 */
export function calculateRowLablePositions(seatsUnsorted: VenueSeatData[], labelDistance: number): RowLabelPositions {
    const seats = seatsUnsorted.slice();

    if (seats.length > 1) {
        // Plätze in eine definierte Reihenfolge bringen, um Anfang und Ende der Reihe zu bestimmen.
        seats.sort(compareSeatLabels);

        // hier wissen wir, dass es min. 2 Plätze im Array gibt, die Zugriffe sollten daher niemals undefined liefern.
        const firstSeat = seats[0];
        const secondSeat = seats[1];
        const lastSeat = seats[seats.length - 1];
        const penultimateSeat = seats[seats.length - 2];

        return {
            start: calculateRowLabelPosition(firstSeat, secondSeat, labelDistance, 'START'),
            end: calculateRowLabelPosition(lastSeat, penultimateSeat, labelDistance, 'END'),
        };
    } else if (seats.length === 1) {
        return {
            start: calculateRowLabelPositionForDegenerateCase(seats[0], labelDistance, 'START'),
            end: calculateRowLabelPositionForDegenerateCase(seats[0], labelDistance, 'END'),
        };
    }

    throw new Error('unable to calculate label positions for empty row');
}


function compareSeatLabels(seat1: VenueSeatData, seat2: VenueSeatData): number {
    return parseInt(seat1.seatLabel) - parseInt(seat2.seatLabel);
}

/**
 * Berechnet die Position eines Reihenlabels anhand von zwei Referenzpunkten.
 *
 * @param anchor Der Ankerpunkt an dem die Label-Position ausgerichtet werden soll.
 * @param control Kontrollpunkt der zur Ausrichtung der Label-Position dient.
 * @param labelDistance Der Abstand, den das Label von anchor Punkt haben soll.
 * @param type Typ des Reihenlabels der berechnet werden soll.
 */
function calculateRowLabelPosition(anchor: IPointData, control: IPointData, labelDistance: number,
    type: RowLabelType): Point
{
    const anchorPoint = new Point().copyFrom(anchor);
    const offset: Point = anchorPoint.subtract(control); 
    const offsetLength = offset.magnitude();

    if (offsetLength > 0) {
        // Durch Verrechnung der Länge mit dem Skalierungsfaktor ist eine Normalisierung des offset hier unnötig.
        return anchorPoint.add(offset.multiplyScalar(labelDistance / offsetLength)); 
    }

    return calculateRowLabelPositionForDegenerateCase(anchorPoint, labelDistance, type);
}

/**
 * Berechnet die Position eines Reihenlabels für den Sonderfall, dass keine Ausrichtung
 * anhand von zwei Referenzpunkten bestimmte werden kann.
 *
 * @param anchor Der Ankerpunkt an dem die Label-Position ausgerichtet werden soll.
 * @param labelDistance Der Abstand, den das Label von anchor Punkt haben soll.
 * @param type Typ des Reihenlabels der berechnet werden soll.
 */
function calculateRowLabelPositionForDegenerateCase(anchor: IPointData, labelDistance: number, type: RowLabelType)
    : Point
{
    const anchorPoint = new Point().copyFrom(anchor);
    switch (type) {
        case 'START':
            return anchorPoint.add(new Point(-labelDistance, 0));
        case 'END':
            return anchorPoint.add(new Point(+labelDistance, 0));
    }
}
