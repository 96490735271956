import React from 'react';
import { CartItem, PlaceType, Seat } from '../../state/Checkout';
import { useLocale } from '../../state/Localization';
import { Button } from '@mui/material';
import style from './style.module.css';

export interface CheckoutSummaryItemProps {
  cartItem: CartItem;
  startDate: string;
  downloadLink: string;
  titleEvent: string | null | undefined;
  venueName?: string;
}

export const determineSeat = (
  toBeDetermined: PlaceType,
): toBeDetermined is Seat => {
  return !!(toBeDetermined as Seat).type;
};

const CheckoutSummaryItemComponent: React.FC<CheckoutSummaryItemProps> = ({
  cartItem,
  downloadLink,
  titleEvent,
  venueName,
  startDate,
}) => {
  const { language, strings } = useLocale();

  const getRowLabel = (toBePrinted: PlaceType): string => {
    if (determineSeat(toBePrinted)) {
      return `${strings.Checkout_Summary_Event_Table_Row}: ${toBePrinted.rowLabel}`;
    } else {
      return '';
    }
  };
  const getSeatLabel = (toBePrinted: PlaceType): string => {
    if (determineSeat(toBePrinted)) {
      return `${strings.Checkout_Summary_Event_Table_Place}: ${toBePrinted.seatLabel}`;
    } else {
      return '';
    }
  };

  return (
    <div className={style.Item}>
      <div className={style.ItemDetails}>
        <div className={style.ItemTitle}>
          {titleEvent}
        </div>
        <div className={style.ItemEvent}>
          {`${startDate} ${strings.Shared_TimeSuffix} ${venueName}`}
        </div>
        <div className={style.ItemCategory}>
          {cartItem.publicName.de} {cartItem.pricingCategory.name}
        </div>
        <div className={style.ItemPricingClass}>
          {cartItem.pricingClass.publicName.de},
          Block {cartItem.place.block.label.de},
          {cartItem.place.type === 'seat'
              ? ` ${getRowLabel(cartItem.place)}, ${getSeatLabel(cartItem.place)}`
              : ` ${strings.StandingPlace}`},
          {` ${(cartItem.grossAmount / 100).toLocaleString(language, {
            currency: 'EUR',
            style: 'currency',
          })}`}
        </div>
      </div>
      <div className={style.ItemDownload}>
        <Button
          variant="contained"
          href={downloadLink}
          target="_blank"
          rel="noreferrer"
        >
          {strings.Checkout_Thanks_Pdf}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutSummaryItemComponent;
