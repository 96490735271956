import React, { useEffect } from 'react';
import {
  getEventsList,
  useSalesChannelEventsState
} from '../../state/SalesChannelEvents';
import { useLocale } from '../../state/Localization';
import { useLocation, useRouteMatch } from 'react-router-dom';
import EventsList from '../../components/EventsList';
import queryString from 'query-string';
import Spinner from '../../common/Spinner';
import { useDispatch } from 'react-redux';
import style from './style.module.css';

const SalesChannelEventListScreen: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { strings } = useLocale();
  const searchParams = queryString.parse(location.search);
  const salesChannelKey = searchParams.sales_channel as string;
  const eventsListState = useSalesChannelEventsState();
  const { tenantSlug } = useRouteMatch<{
    tenantSlug: string;
  }>().params;

  useEffect(() => {
    dispatch(getEventsList(salesChannelKey));
  }, [dispatch]);
  
  return (
    eventsListState.fetching ? (
      <Spinner />
    ) : (
      <div className={style.EventList}>
        <h1>{strings.EventListingTitle} ({salesChannelKey})</h1>
        <EventsList
          eventsListState={eventsListState}
          salesChannelKey={salesChannelKey}
          tenantSlug={tenantSlug}
        />
      </div>
      // 
    )
  );
};

export default SalesChannelEventListScreen;
