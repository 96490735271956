import React from 'react';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useLocale } from '../../../state/Localization';

export interface CheckoutPaymentProps {
  handlePayment: () => void;
}

const CheckoutPaymentComponent: React.FC<CheckoutPaymentProps> = ({
  handlePayment,
}) => {
  const { strings } = useLocale();

  return (
    <Button
      onClick={handlePayment}
      color="primary"
      variant="contained"
      endIcon={<ChevronRight />}
    >
      {strings.Checkout_Payment_Btn}
    </Button>
  );
};

export default CheckoutPaymentComponent;
