import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton
} from '@mui/material';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';
import {
  addPlacesPersonalization,
  usePlacesPersonalization,
} from '../../state/PlacePersonalization';
import {
  clearPurchasableItems,
  loadPurchasableItemsForPlace,
  usePurchasableItems,
} from '../../state/PurchasableItems';
import {
  Place,
  deletePlaces,
  setPurchasableItem,
} from '../../state/TicketSelection';
import { useVenueEvent } from '../../state/VenueEvent';
import style from './style.module.css';

const TicketComponent: React.FC<{
  place: Place;
  index: number;
  reseating?: boolean;
}> = ({ place, index, reseating }) => {
  const dispatch = useDispatch();
  const { fetchComponent, fetchIndicator } = useFetch();
  const { language, strings } = useLocale();
  const purchasableItemsState = usePurchasableItems();
  const placesPersonalization = usePlacesPersonalization();
  const venueEvent = useVenueEvent();

  const [expanded, setExpanded] = useState(false);
  const [currentPlaceId, setCurrentPlaceId] = useState<string>();

  useEffect(() => {
    setExpanded(purchasableItemsState?.placeId === place.id);
  }, [purchasableItemsState?.placeId]);

  useEffect(() => {
    setCurrentPlaceId(purchasableItemsState?.placeId);
  }, [purchasableItemsState?.placeId]);

  const onPurchasableItemOptionClicked = useCallback(
    (id: string) => {
      if (id !== currentPlaceId) {
        dispatch(setPurchasableItem(place.id, id, fetchComponent));
      }
      dispatch(clearPurchasableItems());
    },
    [dispatch, place.id, place.selectedPurchasableItem, fetchComponent]
  );

  const onChangePurchasableItemClicked = useCallback(() => {
    if (currentPlaceId !== place.id) {
      setCurrentPlaceId(place.id);
      dispatch(loadPurchasableItemsForPlace(place.id, fetchComponent));
    } else {
      setCurrentPlaceId(undefined);
      dispatch(clearPurchasableItems());
    }
  }, [currentPlaceId, dispatch, fetchComponent]);

  const onDeletePlaceClicked = useCallback(() => {
    dispatch(deletePlaces([place.id], fetchComponent));
  }, [dispatch, place.id, fetchComponent]);

  const setFullName = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        addPlacesPersonalization(place.id, eventArgs.target.value, null, null),
      );
    },
    [dispatch, place.id, fetchComponent],
  );

  const setPhoneNumber = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      const regex = RegExp('^[+]?[0-9( )\\/-]*$');

      if (regex.exec(eventArgs.target.value) !== null) {
        dispatch(
          addPlacesPersonalization(
            place.id,
            null,
            eventArgs.target.value,
            null,
          ),
        );
      }
    },
    [dispatch, place.id, fetchComponent],
  );

  const setSeasonTicketLegalRecipientId = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        addPlacesPersonalization(place.id, null, null, eventArgs.target.value),
      );
    },
    [dispatch, place.id, fetchComponent],
  );

  const getFullName = (): string =>
    placesPersonalization.placesPersonalization[index]?.fullName ?? '';

  const getSeasonTicketLegalRecipientId = (): string =>
    placesPersonalization.placesPersonalization[index]
      ?.seasonTicketLegalRecipientId ?? '';

  const placeIsExpired = place.expiresAt < new Date();

  return (
    <div className={`${style.Ticket} ${reseating ? style.TicketReseating : ''}`}>
      <div className={style.SpreadRow}>
        <div className={style.TicketTitle}>
          {place.selectedPurchasableItem
            ? place.selectedPurchasableItem?.publicName +
              ' ' +
              place.pricingCategory.name
            : place.pricingCategory.name}
        </div>
        <IconButton
          size='small'
          color="primary"
          onClick={onDeletePlaceClicked}
          title={strings.Tickets_Item_Remove}
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      </div>

      <div>
        {venueEvent.venueEvent
          ?.seasonTicketLegalRecipientShouldBeCollected && (
          <>
            <p className={style.Label}>
              {strings.Tickets_Item_SeasonTicketLegalRecipientId}
            </p>
            <input
              type="text"
              onChange={setSeasonTicketLegalRecipientId}
              value={getSeasonTicketLegalRecipientId()}
            />
            <div
              className={style.Margin}
              data-testid={`margin${index}`}
            ></div>
          </>
        )}

        {venueEvent.venueEvent?.personalDataOnTicketShouldBeCollected && (
          <>
            <p className={style.Label}>
              {strings.Tickets_Item_PersonalizationData_Label}
            </p>
            <input
              type="text"
              data-testid={`name${index}`}
              onChange={setFullName}
              readOnly={placeIsExpired}
              value={getFullName()}
            />
            {venueEvent.venueEvent?.personalDataOnTicketIsRequired &&
            getFullName().length < 1 &&
            !placeIsExpired ? (
              <p className={style.Warning} data-testid={`warning${index}`}>
                {strings.Tickets_Item_Name_Required}
              </p>
            ) : (
              <div
                className={style.Margin}
                data-testid={`margin${index}`}
              ></div>
            )}
          </>
        )}

        {place.blockType === 'seating' ? (
          <div className={style.TicketBlock}>
            {strings.Shared_Block}&nbsp;{place.blockLabel}
            {', '}
            {strings.Shared_Row}&nbsp;{place.rowLabel}
            {', '}
            {strings.Shared_Seat}&nbsp;{place.seatLabel}
          </div>
        ) : (
          <div className={style.TicketBlock}>
            {strings.Shared_Block}&nbsp;{place.blockLabel}
          </div>
        )}
        {place.rightsProvider ? (
          <div className={style.TicketTitle}>
            {strings.Tickets_Item_Rights_Provider}{' '}
            {place.rightsProvider.emailOrEfId}
          </div>
        ) : null}

        {place.selectedPurchasableItem &&
          (
            <div className={style.SelectedPurchasableItemContainer}>
              <Accordion
                disabled={placeIsExpired}
                expanded={expanded && !placeIsExpired}
                disableGutters
              >
                <AccordionSummary
                  onClick={onChangePurchasableItemClicked}
                  expandIcon={(
                    fetchIndicator.fetching &&
                    currentPlaceId === place.id)
                    ? <CircularProgress size={'21px'} color={'secondary'} />
                    : <ExpandMore />
                  }
                >
                  <div className={style.TicketSelect}>
                    <div>{place.selectedPurchasableItem.pricingClass.publicName}</div>
                    {!placeIsExpired && (
                      <div>
                        {(
                          (place.selectedPurchasableItem?.grossAmount ?? 0) / 100
                        ).toLocaleString(language, {
                          currency: 'EUR',
                          style: 'currency',
                        })}
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {purchasableItemsState?.purchasableItems.map(
                    (purchasableItem, key) => (
                      <div
                        className={style.TicketOption}
                        key={key}
                        onClick={() => onPurchasableItemOptionClicked(purchasableItem.id)}
                      >
                        <div>{purchasableItem.pricingClass.publicName}</div>
                        <div>{((purchasableItem.grossAmount ?? 0) / 100).toLocaleString(language, {
                          currency: 'EUR',
                          style: 'currency',
                        })}</div>
                      </div>
                    ),
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )
        }
      </div>
      <div className={style.SpreadRow}>
        {placeIsExpired && (
          <span className={style.ExpiredRow}>
            {strings.Tickets_Item_Expired}
          </span>
        )}
        {/* <Button
          fetchIndicator={fetchIndicator}
          onClick={onDeletePlaceClicked}
          variant="sub"
        >
          {strings.Tickets_Item_Remove}
        </Button> */}
      </div>
      {place.bundleMessage && (
        <div>
          <span className={style.BundleMessageRow}>{place.bundleMessage}</span>
        </div>
      )}
    </div>
  );
};

export default TicketComponent;
