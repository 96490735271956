import { Action, ActionType, FetchState } from './types';

const initialState: FetchState = {
  activeScopes: 0,
  components: new Map(),
};

export const reducer = (state = initialState, action: Action): FetchState => {
  switch (action.type) {
    case ActionType.START: {
      const { id, scope } = action.payload;
      const activeScopes = state.activeScopes | scope;
      const components = new Map(state.components);
      components.set(id, { id, scope });
      return {
        ...state,
        activeScopes,
        components,
        focus: id,
      };
    }
    case ActionType.STOP: {
      const { id } = action.payload;
      const focus = state.focus === id ? undefined : state.focus;
      const components = new Map(state.components);
      components.delete(id);
      let activeScopes = 0;
      for (const component of components.values()) {
        activeScopes |= component.scope;
      }
      return {
        ...state,
        activeScopes,
        components,
        focus,
      };
    }
    default: {
      return state;
    }
  }
};
