export enum ActionType {
  START = 'FETCH::START',
  STOP = 'FETCH::STOP',
}

export enum FetchScope {
  SINGLE = 0,
  ALL = 1,
}

export interface FetchComponent {
  id: string;
  scope: FetchScope;
}

export interface FetchIndicator {
  fetching: boolean;
  hasFocus: boolean;
}

export interface FetchState {
  activeScopes: number;
  components: Map<string, FetchComponent>;
  focus?: string;
}

export interface StartAction {
  payload: FetchComponent;
  type: typeof ActionType.START;
}

export interface StopAction {
  payload: { id: string };
  type: typeof ActionType.STOP;
}

export type Action = StartAction | StopAction;
