import { isEmpty, pickBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '../../config.json';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';
import { clearPurchasableItems } from '../../state/PurchasableItems';
import { useSession } from '../../state/Session';
import { Place, useTicketSelection } from '../../state/TicketSelection';
import { usePlacesReseating } from '../../state/PlaceReseating';
import { processReseating } from '../../state/PlaceReseating/actions';
import { addSeats, deletePlaces } from '../../state/TicketSelection/actions';
import {
  HTTPKnownError,
  KnowErrors,
  apiGETRequest,
  generateURL,
} from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import StandingPlaceSelection from '../StandingPlaceSelection';
import StandingPlaceReseating from '../StandingPlaceReseating';
import VenuePlan, {
  PlacesAvailabilityData,
  PlacesSelection,
  VenueAvailabilityData,
  VenuePlacesData,
  VenueSelectedPlace,
} from '../VenuePlan';
import {
  ActionType as PlaceReseatingActionType,
  Mode as PlaceReseatingMode,
} from '../../state/PlaceReseating/types';
import { BlockSelectionEvent, SelectionEvent } from '../VenuePlan/interaction';
import { PlaceGraphicalState } from '../VenuePlan/PlaceGraphicalState';
import { toast } from 'react-toastify';
import style from './style.module.css';
import { Seat } from '../../state/TicketSelection/types/Place';
import { getVenueEvent } from '../../state/VenueEvent';

export const AVAILABILITY_UPDATE_INTERVAL = 5000;
const SEAT_QUERY_SERIALIZATION_VARIANT_FULL = 'place_selection';
const SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY = 'id_only';

export interface VenuePlanProps {
  availability: VenueAvailabilityData;
  setAvailability: (
    value:
      | ((prevState: VenueAvailabilityData) => VenueAvailabilityData)
      | VenueAvailabilityData,
  ) => void;

  /**
   * Subject user id for backend purchase.
   */
  purchaseForTicketHolderId: string;

  /**
   * Subscription id id for season ticket purchase.
   */
  subscriptionId: string;

  /**
   * Sales rule id for backend purchase.
   */
  salesRuleId: string;

  /**
   * The sales channel in which the place selection takes place.
   */
  salesChannel: string;

  /**
   * The ID of the event for which the venue plan should be displayed.
   */
  eventId: string;

  /**
   * Queue it token for the event.
   */
  queueItToken: string;

  /**
   * The name of the venue layout to use.
   */
  venueLayout: string;

  /**
   * Unique venue plan version
   */
  venuePlanVersionId: string;
}

/**
 * Component that represents a graphical interface for selecting places in a venue.
 */
export const PlaceSelection: React.FC<VenuePlanProps> = (props) => {
  const { fetchComponent, fetchIndicator } = useFetch();
  const { strings } = useLocale();
  const { selectedRightsProvider } = useSession();
  const ticketSelection = useTicketSelection();
  const placeReseating = usePlacesReseating();

  const dispatch = useDispatch();

  const [
    isShowModalChooseStandingPlace,
    setIsShowModalChooseStandingPlace,
  ] = useState(false);
  const [
    isShowModalReseatStandingPlace,
    setIsShowModalReseatStandingPlace,
  ] = useState(false);
  const [placesInCurrentBlock, setPlacesInCurrentBlock] = useState(0);
  const [
    selectedBlockForStandingPlace,
    setSelectedBlockForStandingPlace,
  ] = useState<BlockSelectionEvent | undefined>(undefined);
  const [
    availablePlacesInSelectedBlockForStandingPlace,
    setAvailablePlacesInSelectedBlockForStandingPlace,
  ] = useState(0);

  const [pendingSelections, setPendingSelections] = useState<string[]>([]);
  const [placesData, setPlacesData] = useState<VenuePlacesData>({
    images: [],
    seats: [],
    standingBlocks: [],
    venuePlanSettings: {
      showRowNumberAtBeginningOfRow: true,
      showRowNumberAtEndOfRow: true,
      showSeatLabels: true,
    },
  });
  const { availability, setAvailability } = props;
  const [isPlanLoading, setIsPlanLoading] = useState(true);

  const determineSeat = (
    toBeDetermined: Place,
  ): toBeDetermined is Seat => {
    return !!(toBeDetermined as Seat).id;
  };

  const selectedPlaces = ticketSelection?.places || [];
  const selectedSeats: VenueSelectedPlace[] = selectedPlaces
    .filter((p) => determineSeat(p))
    .map((s) => ({ place: { id: determineSeat(s) ? s.seatId : '', type: 'seat' }, status: 'selected' }));

  const placesSelection: PlacesSelection = {
    // extend selected seats by pending selections to give immediate feedback on selection.
    // FIXME: Handling of this this should probably move into the global state.
    seats: selectedSeats.concat(
      pendingSelections.map((id) => ({
        place: { id, type: 'seat' },
        status: 'processing',
      })),
    ),
    standingPlaces: selectedPlaces.reduce((acc: Record<string, number>, p) => {
      if (p.blockType === 'standing') {
        acc[p.blockId] = (acc[p.blockId] ?? 0) + 1;
      }
      return acc;
    }, {}),
  };

  /**
   * @param availableOnly Flag if only data for available seats should be return.
   * @param idOnly Flag if serialization limited to the ID should be requested.
   */
  function createSeatQueryParams({ idOnly = false } = {}): {
    [key: string]: string;
  } {
    const serializerGroup = idOnly
      ? SEAT_QUERY_SERIALIZATION_VARIANT_ID_ONLY
      : SEAT_QUERY_SERIALIZATION_VARIANT_FULL;

    return pickBy({
      purchaseForTicketHolderId: props.purchaseForTicketHolderId,
      subscriptionId: props.subscriptionId,
      salesRuleId: props.salesRuleId,
      rightsProviderId: selectedRightsProvider?.id ?? '',
      salesChannel: props.salesChannel,
      serializerGroup,
    });
  }

  function allowShowModalReseatStandingPlace(
    blockId: string,
    availablePlacesInBlock: number,
  ): boolean {
    const reseatingPlacesInBlock = !!placeReseating.places.find(
      (place) => place.blockId === blockId,
    );
    const completedPlacesInBlock = !!ticketSelection?.places.find(
      (place) => place.blockId === blockId,
    );
    const selectedPlaces = !!placeReseating.venuePlaces.find(
      (place) => place.status === 'reseating-selected',
    );
    return (
      reseatingPlacesInBlock ||
      completedPlacesInBlock ||
      (selectedPlaces && !!availablePlacesInBlock)
    );
  }

  /**
   * @param event The selection event to handle.
   */
  function handleSelectionEvent(event: SelectionEvent): void {
    // Since the API currently only supports selecting seats one-by-one we block
    // selection until the result of the previous request is known to avoid
    // inconsistencies between UI and cart state.
    if (!fetchIndicator.fetching) {
      switch (event.type) {
        case 'BLOCK': {
          const availablePlacesInBlock = availability.getRemainingBlockCapacity(
            event.blockId,
          );
          const allowShowModalReseat = allowShowModalReseatStandingPlace(
            event.blockId,
            availablePlacesInBlock,
          );

          if (availablePlacesInBlock) {
            setSelectedBlockForStandingPlace(event);
            setAvailablePlacesInSelectedBlockForStandingPlace(
              availablePlacesInBlock,
            );
            setPlacesInCurrentBlock(
              placesSelection.standingPlaces[event.blockId] ?? 0,
            );
          }

          if (availablePlacesInBlock && props.salesChannel !== 'reseating') {
            setIsShowModalChooseStandingPlace(true);
          } else if (allowShowModalReseat) {
            setIsShowModalReseatStandingPlace(true);
          } else {
            toast.error(strings.Error_reseating_no_selection, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          break;
        }
        case 'SEAT': {
          // In reseating only allow selection if place for reseating exist

          const seatIdToDelete = selectedPlaces.find(
            (place) => determineSeat(place)
              ? place.seatId === event.seatId
              : '' === event.seatId // TODO: Why and when does this apply?
          )?.id;

          if (
            props.salesChannel !== 'reseating' ||
            placeReseating.selectedPlaces.length ||
            !event.selected
          ) {
            setPendingSelections((prevState) =>
              event.selected ? prevState.concat([event.seatId]) : [],
            );
            dispatch((event.selected
              ? addSeats([event.seatId], fetchComponent)
              : deletePlaces([seatIdToDelete] as string[], fetchComponent)
            ));
          } else {
            toast.error(strings.Error_reseating_no_selection, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          break;
        }
        case 'SEATS': {
          const selectPlacesIds: string[] = [];
          const deselectPlacesIds: string[] = [];

          event.seats.map((seat) => {
            let select = true;
            switch (seat.state) {
              case PlaceGraphicalState.AVAILABLE:
                select = true;
                break;
              case PlaceGraphicalState.SELECTED:
              case PlaceGraphicalState.PROCESSING:
                select = false;
                break;
              default:
                return;
            }

            if (select) {
              selectPlacesIds.push(seat.id);
            } else {
              deselectPlacesIds.push(seat.id);
            }
          });

          if (deselectPlacesIds.length) {
            dispatch(deletePlaces(deselectPlacesIds, fetchComponent));
          }

          if (selectPlacesIds.length) {
            dispatch(addSeats(selectPlacesIds, fetchComponent));
          }

          break;
        }
      }
    }

    if (event.type === 'SEAT') {
      dispatch(clearPurchasableItems());
    }
  }

  function handleSelectionReseatingEvent(event: SelectionEvent): void {
    switch (event.type) {
      case 'SEAT':
        dispatch(processReseating(event.seatId));
        break;
    }
  }

  function setAvailabilityFromData(
    availabilityData: PlacesAvailabilityData,
  ): void {
    const idsOfAvailableSeats = new Set(
      availabilityData.seats.map((s) => s.id),
    );
    const capacityByBlock = new Map(
      availabilityData.standingBlocks.map((b) => [b.id, b.availableCapacity]),
    );
    const getRemainingBlockCapacity = (blockId: string): number =>
      capacityByBlock.get(blockId) ?? 0;

    setAvailability((prevState) => {
      return {
        ...prevState,
        isSeatAvailable(seatId) {
          return idsOfAvailableSeats.has(seatId);
        },
        isBlockAvailable(blockId: string): boolean {
          return getRemainingBlockCapacity(blockId) > 0;
        },
        getRemainingBlockCapacity,
        availableSeatsCount: availabilityData.seats.length,
        version: prevState.version + 1,
      };
    });
  }

  // update of seat availability is performed periodically via setTimout()
  // setup by useEffect(). To be able to access the current props & state
  // for the request we store the update function in a ref on each
  // render to close over the current props & state obviating the need
  // to cancel any pending updates. For further explanation please refer to
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const updateAvailability = useRef<() => Promise<void>>();

  useEffect(() => {
    updateAvailability.current = async () => {
      const requestURL = generateURL(config.API_ENDPOINTS.GET_AVAILABLE_SEATS, {
        params: {
          venueEventId: props.eventId,
          salesChannelKey: props.salesChannel,
        },
        query: createSeatQueryParams({ idOnly: true }),
      });
      return apiGETRequest(requestURL).then(
        (availabilityData: PlacesAvailabilityData) => {
          if (props.venuePlanVersionId !== null && props.venuePlanVersionId !== availabilityData.venuePlanVersionId) {
            dispatch(getVenueEvent(props.eventId, props.salesChannel, props.queueItToken));
            return;
          }

          if (
            availabilityData.errorType !== null &&
            availabilityData.errorType !== undefined
          ) {
            if (
              (KnowErrors.FreePlacesAreMissing as string) == availabilityData.errorType ||
              (KnowErrors.UserHasMaxTickets as string) == availabilityData.errorType
            ) {
              if (
                isEmpty(placesSelection.standingPlaces) &&
                placesSelection.seats.length === 0
              ) {
                handleError(
                  dispatch,
                  new HTTPKnownError(200, availabilityData.errorType),
                );
              }
            } else {
              handleError(
                dispatch,
                new HTTPKnownError(200, availabilityData.errorType),
              );
            }
          }

          // We pretend that all seats that are currently selected are available to avoid the weird situation
          // where a seat becomes unavailable for a short time when the user removes it from his selection but
          // the availability has not been refreshed yet.
          availabilityData.seats = availabilityData.seats.concat(
            selectedSeats.map((s) => ({ id: s.place.id })),
          );
          setAvailabilityFromData(availabilityData);
        },
        (error) => {
          if (error instanceof Error) {
            // handleError(dispatch, error);
          }
        },
      );
    };
  });

  // trigger initial load of venue plan data
  useEffect(() => {
    const loadPlacesData = async () => {
      const requestURL = generateURL(config.API_ENDPOINTS.GET_SEATS, {
        params: {
          venuePlanVersionId: props.venuePlanVersionId,
          salesChannelKey: props.salesChannel,
        },
        query: createSeatQueryParams(),
      });

      return apiGETRequest(requestURL);
    };

    const loadVenuePlanData = async () => {
      try {
        const [placesData] = await Promise.all([
          loadPlacesData() as Promise<VenuePlacesData>,
        ]);

        setPlacesData(placesData);
      } catch (error) {
        if (error instanceof Error) {
          handleError(dispatch, error);
        }
      }
    };

    loadVenuePlanData();
    // The venue plan itself is not dependent on the currently selected
    // sales rule therefore it is not listed here.
  }, [props.eventId, props.venueLayout, props.venuePlanVersionId]);

  // Start periodic update of seat availability.
  //
  // This needs to be done on the first render ONLY as the request to fetch the
  // availability is placed in a ref it is guaranteed that the currently
  // selected parameters  like sales rule etc. are used, therefore it is not
  // necessary to schedule this periodic update anew if those parameters change.
  useEffect(() => {
    const updateInterval = () => {
      updateAvailability.current?.();
    };
    updateInterval();
    const id = setInterval(updateInterval, AVAILABILITY_UPDATE_INTERVAL);
    return () => clearInterval(id);
  }, [props.venuePlanVersionId]);

  // reset any locally known pending selections when the ticket selection
  // state changes as this is the ultimate source of truth.
  useEffect(() => {
    setPendingSelections([]);
  }, [ticketSelection]);

  // When places are selected or deselected in the reaseating process the status
  // of the original seet is set to complete. This also ensures that completed
  // reseatings are restored after reload. Reaseating states are stored locally
  // and can be determined by the selected tickets via their reseatingContractId.
  useEffect(() => {
    if (ticketSelection) {
      ticketSelection.places.map((selectedPlace) => {
        const reseatingSelectedPlace = placeReseating.venuePlaces.find(
          (reseatingVenuePlace) =>
            reseatingVenuePlace.place.contractId ===
            selectedPlace.reseatingContractId,
        );

        if (
          reseatingSelectedPlace &&
          reseatingSelectedPlace.status !== 'reseating-completed'
        ) {
          dispatch({
            payload: { placeId: reseatingSelectedPlace.place.id },
            type: PlaceReseatingActionType.REMOVE_SELECTED_PLACE,
          });
          dispatch({
            payload: {
              placeId: reseatingSelectedPlace.place.id,
              mode: PlaceReseatingMode.Completed,
            },
            type: PlaceReseatingActionType.TOGGLE_PLACE_MODE,
          });
          dispatch({
            payload: { placeId: reseatingSelectedPlace.place.id },
            type: PlaceReseatingActionType.COMPLETE,
          });
        }
      });

      // Remove completed state if there is no seat with it's reseatingContractId is selected
      placeReseating.venuePlaces.map((venuePlace) => {
        const selectedPlace = ticketSelection.places.find(
          (place) => place.reseatingContractId === venuePlace.place.contractId,
        );

        if (venuePlace.status === 'reseating-completed' && !selectedPlace) {
          dispatch({
            payload: {
              placeId: venuePlace.place.id,
              reseatingContractId: venuePlace.place.contractId,
            },
            type: PlaceReseatingActionType.ADD_SELECTED_PLACE,
            options: { unshift: true },
          });
          dispatch({
            payload: {
              placeId: venuePlace.place.id,
              mode: PlaceReseatingMode.Selected,
            },
            type: PlaceReseatingActionType.TOGGLE_PLACE_MODE,
          });
          dispatch({
            payload: { placeId: venuePlace.place.id },
            type: PlaceReseatingActionType.SELECT,
          });
        }
      });
    }
  }, [ticketSelection, placeReseating.venuePlaces]);


  const handlePlanLoaded = () => {
    setIsPlanLoading(false);
  };

  // When parameters that possibly influence the availability of seats change
  // we have no choice but to assume that no seats are available to prevent
  // erroneous selections as we won't know which seats are available under
  // the new  parameters until we receive the next update from the API.
  useEffect(() => {
    setAvailabilityFromData({ seats: [], standingBlocks: [], errorType: null, venuePlanVersionId: null });
  }, [props.salesRuleId, props.salesChannel, selectedRightsProvider]);

  const isShowLoader = isPlanLoading || availability && availability.version < 2;

  return (
    <>
      <div className={style.VenuePlan}>
        <div>
          {isShowLoader && (
            <span className={style.PositionLoader}>
              <div className={style.Loader}></div>
            </span>
          )}
          <div style={{ opacity: isShowLoader ? '0.2' : '1' }}>
            {!isEmpty(placesData.seats) && (
              <VenuePlan
                places={placesData}
                placesSelection={placesSelection}
                placesReseating={placeReseating}
                salesChannel={props.salesChannel}
                availability={availability}
                isVisible={
                  !isEmpty(placesData.seats) ||
                  !isEmpty(placesData.standingBlocks)
                }
                onSelectionEvent={handleSelectionEvent}
                onSelectionReseatingEvent={handleSelectionReseatingEvent}
                onLoaded={handlePlanLoaded}
                isShowLoader={isShowLoader}
              />
            )}
          </div>
        </div>
      </div>
      <StandingPlaceSelection
        isShowModalChooseStandingPlace={isShowModalChooseStandingPlace}
        setIsShowModalChooseStandingPlace={setIsShowModalChooseStandingPlace}
        selectedBlockForStandingPlace={selectedBlockForStandingPlace}
        availablePlacesInSelectedBlockForStandingPlace={
          availablePlacesInSelectedBlockForStandingPlace
        }
        placesInCurrentBlock={placesInCurrentBlock}
      />
      <StandingPlaceReseating
        isShowModalReseatStandingPlace={isShowModalReseatStandingPlace}
        setIsShowModalReaseatStandingPlace={setIsShowModalReseatStandingPlace}
        selectedBlockForStandingPlace={selectedBlockForStandingPlace}
        availablePlacesInSelectedBlockForStandingPlace={
          availablePlacesInSelectedBlockForStandingPlace
        }
      />
    </>
  );
};

export default PlaceSelection;
