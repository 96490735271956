import { Sprite } from 'pixi.js';
import { VenueSeatData } from './data';
import { getSeatTexture } from './resources';
import { PlaceGraphicalState } from './PlaceGraphicalState';

import Color from 'color';

import { ease } from 'pixi-ease';
import {
  COLOR_SEAT_DEFAULT,
  TINT_SEAT_LABEL_AVAILABLE,
  TINT_SEAT_LABEL_SELECTED,
  TINT_SEAT_LABEL_UNAVAILABLE,
  TINT_SEAT_RESEATING,
  TINT_SEAT_UNAVAILABLE,
} from './colors';

import { SEAT_SIZE, BF_SEAT_LABEL_SIZE, DEFAULT_SEAT_SCALE } from './constants';

let SEAT_SPRITE_UNDER_POINTER: Sprite | undefined;

export const SEAT_BY_ID = new Map<string, VenueSeatData>();

export function getSeatById(id: string): VenueSeatData | undefined {
  return SEAT_BY_ID.get(id);
}

export function getSeatSpriteById(id: string): Sprite | undefined {
  return SEAT_BY_ID.get(id)?.sprites?.seat;
}

const SEAT_POINTER_OVER_EASE_OPTIONS = {
  duration: 50,
  ease: 'easeOutCubic',
  removeExisting: true,
};

const SEAT_POINTER_OUT_EASE_OPTIONS = {
  duration: 75,
  ease: 'easeOutQuad',
  removeExisting: true,
};

async function updateSeatDisplayState(seat: VenueSeatData): Promise<void> {
  if (!seat.sprites) {
    return; // bail out if the seat has no assigned sprites;
  }

  const seatSprite = seat.sprites.seat;
  const labelSprite = seat.sprites.label;

  const prevScale = seatSprite.scale.x;
  const seatColor = Color(seat.color ?? COLOR_SEAT_DEFAULT).rgbNumber();
  const seatColorFaded = Color(seat.color ?? COLOR_SEAT_DEFAULT)
    .desaturate(0.5)
    .lightness(75)
    .rgbNumber();

  seatSprite.texture = await getSeatTexture(seat.state);

  switch (seat.state) {
    case PlaceGraphicalState.AVAILABLE:
      seatSprite.interactive = true;
      seatSprite.tint = seatColor;
      labelSprite.tint = TINT_SEAT_LABEL_AVAILABLE;
      break;
    case PlaceGraphicalState.UNAVAILABLE:
      seatSprite.interactive = false;
      seatSprite.tint = TINT_SEAT_UNAVAILABLE;
      labelSprite.tint = TINT_SEAT_LABEL_UNAVAILABLE;
      break;
    case PlaceGraphicalState.PROCESSING:
    case PlaceGraphicalState.SELECTED:
      seatSprite.interactive = true;
      seatSprite.tint = seatColor;
      labelSprite.tint = TINT_SEAT_LABEL_SELECTED;
      break;
    case PlaceGraphicalState.RESEATING:
      seatSprite.interactive = true;
      seatSprite.tint = TINT_SEAT_RESEATING;
      labelSprite.tint = TINT_SEAT_LABEL_AVAILABLE;
      break;
    case PlaceGraphicalState.RESEATING_SELECTED:
    case PlaceGraphicalState.RESEATING_COMPLETED:
      seatSprite.interactive = true;
      seatSprite.tint = TINT_SEAT_RESEATING;
      labelSprite.tint = TINT_SEAT_LABEL_SELECTED;
      break;
    case PlaceGraphicalState.RESEATING_AVAILABLE:
      seatSprite.interactive = true;
      seatSprite.tint = seatColorFaded;
      labelSprite.tint = TINT_SEAT_LABEL_AVAILABLE;
      break;
  }

  seatSprite.scale.set(prevScale);
}

function onPointerOverSeat(event: MouseEvent): void { // InteractionEvent does not exist in version 7 
  const currentTarget = event.currentTarget as (Sprite & { name: string } | null);
  if (!currentTarget) return;

  const seat = getSeatById(currentTarget.name);

  if (seat?.sprites) {
    SEAT_SPRITE_UNDER_POINTER = seat.sprites.seat;

    ease.add(
      seat.sprites.seat,
      { scale: DEFAULT_SEAT_SCALE * 1.1 },
      SEAT_POINTER_OVER_EASE_OPTIONS,
    );
    ease.add(
      seat.sprites.label,
      { scale: BF_SEAT_LABEL_SIZE * 1.1 },
      SEAT_POINTER_OVER_EASE_OPTIONS,
    );

    updateSeatDisplayState(seat);
  }
}

function onPointerOutSeat(event: MouseEvent): void { // InteractionEvent does not exist in version 7 
  const currentTarget = event.currentTarget as (Sprite & { name: string } | null);

  if (!currentTarget) return;

  const seat = getSeatById(currentTarget.name);

  if (seat?.sprites) {
    // only if the seat under the pointer is still this seat then cancel this state.
    if (SEAT_SPRITE_UNDER_POINTER === seat.sprites.seat) {
      SEAT_SPRITE_UNDER_POINTER = undefined;
    }

    ease.add(
      seat.sprites.seat,
      { scale: DEFAULT_SEAT_SCALE },
      SEAT_POINTER_OUT_EASE_OPTIONS,
    );
    ease.add(
      seat.sprites.label,
      { scale: BF_SEAT_LABEL_SIZE },
      SEAT_POINTER_OUT_EASE_OPTIONS,
    );

    updateSeatDisplayState(seat);
  }
}

export function setSeatSpriteState(
  seat: VenueSeatData,
  state: PlaceGraphicalState,
): void {
  seat.state = state;

  if (!seat.sprites) {
    return; // bail out if the seat has no assigned sprites;
  }

  updateSeatDisplayState(seat);
}

export async function createSeatSprite(seat: VenueSeatData): Promise<Sprite> {
  const sprite = new Sprite(await getSeatTexture(PlaceGraphicalState.UNKNOWN));

  // associate the sprite with the seat
  sprite.name = seat.id;

  sprite.anchor.set(0.5);
  sprite.width = SEAT_SIZE;
  sprite.height = SEAT_SIZE;
  sprite.interactive = true;
  sprite.cursor  = 'pointer';

  sprite.addListener('pointerover', onPointerOverSeat);
  sprite.addListener('pointerout', onPointerOutSeat);

  return sprite;
}
