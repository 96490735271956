import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocale } from '../../state/Localization';
import useInitParams from '../../util/useInitParams';
import { 
  Button,
  Dialog, 
  DialogActions,
  DialogContent, 
  DialogTitle
} from '@mui/material';

const getNow = (): number => {
  return getSeconds(new Date());
};
const getSeconds = (date: Date): number => {
  return Math.round(new Date(date).getTime() / 1000);
};

const CountdownDialogComponent: React.FC<{ to?: Date }> = ({ to }) => {
  const history = useHistory();
  const { strings } = useLocale();
  const { initParams } = useInitParams();
  
  const [showDialog, setShowDialog] = useState(false);
  const [showShouldDialog, setShouldShowDialog] = useState(false);
  const [now, setNow] = useState(getNow());

  const seconds = to ? Math.max(getSeconds(to) - now, 0) : undefined;

  const handleClick = () => {
    setShowDialog(false);
    if (initParams) {
      history.push(
        `/${initParams.tenantSlug}/placeSelection/` +
        `${initParams.salesChannel}/${initParams.venueEventId}`
      );
    }
  };

  useEffect(() => {
    const timeout = setInterval(() => setNow(getNow()), 1000);
    return (): void => clearInterval(timeout);
  }, []);

  useEffect(() => {
    // In some cases on initialization the timer is 0 for
    // a second. To prevent showing the dialog then, we
    // allow showing only if timer was > 0 before.
    if (seconds && seconds > 0) {
      setShouldShowDialog(true);
    }
    if (showShouldDialog && seconds === 0) {
      setShowDialog(true);
      setShouldShowDialog(false);
    }
  }, [seconds]);

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{strings.Countdown_Title}</DialogTitle>
      <DialogContent>{strings.Countdown_Text}</DialogContent>
      {initParams &&
        <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          {strings.Countdown_Button}
        </Button>
      </DialogActions>
      }
    </Dialog>
  );
};

export default CountdownDialogComponent;
