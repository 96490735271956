import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Place } from '../../state/PlaceReseating';
import Ticket from '../Ticket';
import { useTicketSelection } from '../../state/TicketSelection';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processReseating } from '../../state/PlaceReseating/actions';

const TicketReseating: React.FC<{ place: Place; index: number }> = ({
  place,
  index,
}) => {
  const { language, strings } = useLocale();
  const dispatch = useDispatch();
  const ticketSelection = useTicketSelection();

  const newPlace = ticketSelection?.places.find(
    (newPlace) => newPlace.reseatingContractId === place.contractId,
  );

  const onCheckboxClicked = useCallback(() => {
    dispatch(processReseating(place.id));
  }, [dispatch]);

  return (
    <div className={style.TicketReseating}>
      <div
        className={`${style.TicketReseatingCheckBox}
        ${place.mode as string !== 'view' ? style.TicketReseatingCheckBoxActive : ''}`}
      >
        {place.mode as string !== 'completed' && (
          <div
            className={`${style.TicketReseatingCheckBoxIcon}
            ${
              place.mode as string === 'selected'
                ? style.TicketReseatingCheckBoxIconSelected
                : ''
            }`}
            onClick={onCheckboxClicked}
          >
            {place.mode as string === 'selected' && <FontAwesomeIcon icon={faCheck} />}
          </div>
        )}
      </div>
      <div
        className={`${style.OldPalceBlock} ${
          place.mode as string === 'selected' ? style.OldPalceBlockSelected : ''
        }`}
      >
        <strong>{place.seasonTicketLegalRecipient}</strong>
        {place.blockType === 'seating' ? (
          <p>
            {strings.Shared_Block}&nbsp;{place.blockLabel}
            {', '}
            {strings.Shared_Row}&nbsp;{place.rowLabel}
            {', '}
            {strings.Shared_Seat}&nbsp;{place.seatLabel}
            {', '}
            {place.pricingCategoryName}
          </p>
        ) : (
          <p>
            {strings.Shared_Block}&nbsp;{place.blockLabel}
          </p>
        )}
        <p>
          {`${place.pricingClassName},
            ${((place.grossAmount ?? 0) / 100).toLocaleString(language, {
              currency: 'EUR',
              style: 'currency',
            })}`}
        </p>
      </div>
      <div className={style.NewPalceBlock}>
        {place.mode as string !== 'view' && (
          <>
            <div className={style.ArrowIcon}>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            {place.mode as string === 'selected' && (
              <div className={style.EmptyPlace}>
                <strong>{strings.ReseatingBasket_PleaseSelect}</strong>
              </div>
            )}
            {place.mode as string === 'completed' && newPlace && (
              <div
                className={style.Place}
                style={{ borderColor: newPlace.pricingCategory.color }}
              >
                <Ticket index={index} place={newPlace} reseating={true} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TicketReseating;
