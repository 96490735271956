import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { Dispatch } from 'redux';
import { ActionType, TenantConfig } from './index';
import { handleError } from '../../util/handleError';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import config from '../../config.json';

export const getTenantConfig = (): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_TENANT_CONFIG, {});
    const tenantConfig: TenantConfig = await apiGETRequest(requestURL, true) as TenantConfig;

    dispatch({ payload: { tenantConfig }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};
