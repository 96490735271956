import { Action, ActionType, BlockState } from './types';

const initialState: BlockState = {
  fetching: false,
};

export const reducer = (state = initialState, action: Action): BlockState => {
  switch (action.type) {
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { blocks } = action.payload;
      return {
        ...state,
        blocks: blocks,
        error: undefined,
        fetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
