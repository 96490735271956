import React, { useEffect, useState } from 'react';
import { Update } from '@mui/icons-material';
import style from './style.module.css';

const getNow = (): number => getSeconds(new Date());
const getSeconds = (date: Date): number => {
  return Math.round(date.getTime() / 1000);
};

const CountdownComponent: React.FC<{ to: Date }> = ({ to }) => {
  const [now, setNow] = useState(getNow());

  const seconds = Math.max(getSeconds(to) - now, 0);
  const secondsUnit = seconds % 60;
  const minutesUnit = Math.floor(seconds / 60);

  useEffect(() => {
    const timeout = setTimeout(() => setNow(getNow()), 1000);
    return (): void => clearTimeout(timeout);
  });

  return (
    <span className={style.Countdown}>
      <Update />&nbsp;
      {minutesUnit.toString().padStart(2, '0')}
      :
      {secondsUnit.toString().padStart(2, '0')}
    </span>
  );
};

export default CountdownComponent;
