import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import style from './style.module.css';
import {
  CartItem,
  PlaceType,
  Seat,
  removeCartItem,
} from '../../state/Checkout';
import { useLocale } from '../../state/Localization';
import { useFetch } from '../../state/Fetch';
import { useDispatch } from 'react-redux';

export const isExpired = (cartItem: CartItem): boolean => {
  if (!cartItem.expiresAt) {
    return false;
  }

  return cartItem.expiresAt < new Date();
};

export const determineSeat = (
  toBeDetermined: PlaceType,
): toBeDetermined is Seat => {
  return toBeDetermined.type === 'seat';
};

interface CheckoutSummaryItemProps {
  cartItem: CartItem;
  readonly?: boolean;
}

const CheckoutSummaryItemComponent: React.FC<CheckoutSummaryItemProps> = ({
  cartItem,
  readonly
}) => {
  const { language, strings } = useLocale();
  const { fetchComponent } = useFetch();
  const dispatch = useDispatch();

  const getRowLabel = (toBePrinted: PlaceType): string => {
    if (determineSeat(toBePrinted)) {
      return `${strings.Checkout_Summary_Event_Table_Row} ${toBePrinted.rowLabel}`;
    } else {
      return '';
    }
  };
  const getSeatLabel = (toBePrinted: PlaceType): string => {
    if (determineSeat(toBePrinted)) {
      return `${strings.Checkout_Summary_Event_Table_Place} ${toBePrinted.seatLabel}`;
    } else {
      return '';
    }
  };

  const onDeleteItemClicked = useCallback(() => {
    dispatch(removeCartItem(cartItem.id, fetchComponent));
  }, [dispatch, cartItem.id, fetchComponent]);

  return (
    <div className={style.SummaryItem}>
      <div className={style.ItemTitle}>
        <div>{cartItem.publicName.de} {cartItem.pricingCategory.name}</div>
        <div>
          {(cartItem.grossAmount / 100).toLocaleString(language, {
            currency: 'EUR',
            style: 'currency',
          })}
        </div>
      </div>
      <div className={style.ItemDetails}>
        <div>
          <div>{cartItem.pricingClass.publicName.de}</div>
          <div className={style.ItemBlock}>
            {[
              `Block ${cartItem.place.block.label.de}`,
              getRowLabel(cartItem.place),
              getSeatLabel(cartItem.place)
            ].filter((value) => !!value).join(', ')}
          </div>
        </div>
        {!readonly &&
          <IconButton
            size='small'
            color="primary"
            onClick={onDeleteItemClicked}
            title={strings.Tickets_Item_Remove}
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        }
      </div>
    </div>
  );
};

export default CheckoutSummaryItemComponent;
