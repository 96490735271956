import type { RightsProvider } from '../TicketSelection';
import { UserData } from '../../common/types';

export enum ActionType {
  SET_JWT = 'USER::SET_JWT',
  SET_SALES_CHANNEL = 'USER::SET_SALES_CHANNEL',
  SET_SALES_RULE_ID = 'USER::SET_SALES_SALES_RULE_ID',
  SET_PURCHASE_FOR_TICKET_HOLDER_ID = 'USER::SET_PURCHASE_FOR_TICKET_HOLDER_ID',
  SET_SUBSCRIPTION_ID = 'USER::SET_SUBSCRIPTION_ID',
  SET_QUEUEITOKEN = 'USER::SET_QUEUEITOKEN',
  SET_SELECTED_RIGHTS_PROVIDER = 'USER::SET_SELECTED_RIGHTS_PROVIDER',
  SET_ARE_PLACES_SUBMITTED = 'USER::SET_ARE_PLACES_SUBMITTED'
}

export interface SessionState {
  jwt?: string;
  jwtDecoded?: { [key: string]: string };
  salesChannel?: string;
  purchaseForTicketHolderId?: string;
  subscriptionId?: string;
  queueittoken?: string;
  salesRuleId?: string;
  selectedRightsProvider?: RightsProvider;
  user?: UserData;
  arePlacesSubmitted: boolean;
}

export interface SetJWTAction {
  type: typeof ActionType.SET_JWT;
  payload: { jwt: string };
}

export interface SetPurchaseForTicketHolderIdAction {
  type: typeof ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID;
  payload: { purchaseForTicketHolderId: string };
}

export interface SetSubscriptionIdAction {
  type: typeof ActionType.SET_SUBSCRIPTION_ID;
  payload: { subscriptionId: string };
}

export interface SetQueueittokenAction {
  type: typeof ActionType.SET_QUEUEITOKEN;
  payload: { queueittoken: string };
}

export interface SetSalesChannelAction {
  type: typeof ActionType.SET_SALES_CHANNEL;
  payload: { salesChannel: string };
}

export interface SetSalesRuleIdAction {
  type: typeof ActionType.SET_SALES_RULE_ID;
  payload: { salesRuleId: string };
}

export interface SetSelectedRightsProviderAction {
  type: typeof ActionType.SET_SELECTED_RIGHTS_PROVIDER;
  payload: { selectedRightsProvider: RightsProvider | undefined };
}

export interface SetArePlacesSubmitted {
  type: typeof ActionType.SET_ARE_PLACES_SUBMITTED;
  payload: { arePlacesSubmitted: boolean };
}

export type Action =
  | SetJWTAction
  | SetPurchaseForTicketHolderIdAction
  | SetSubscriptionIdAction
  | SetQueueittokenAction
  | SetSalesChannelAction
  | SetSalesRuleIdAction
  | SetSelectedRightsProviderAction
  | SetArePlacesSubmitted;
