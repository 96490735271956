import { Assets, Texture, Spritesheet, TextStyle, Text } from 'pixi.js';
import log from 'loglevel';
import { PlaceGraphicalState } from './PlaceGraphicalState';

interface LoadedResources {
  [LABEL_SPRITESHEET_NAME]: Spritesheet;
}

const LABEL_SPRITESHEET_NAME = '/sprites.json';
const NUMERIC_LABEL_FALLBACK_TEXTURE = 'label.unknown.png';
const SEAT_SELECTED_TEXTURE = 'seat_circle.png';
const SEAT_AVAILABLE_TEXTURE = 'seat_disc.png';
const SEAT_UNAVAILABLE_TEXTURE = 'seat_disc.png';
const SEAT_COMPLETE_TEXTURE = 'seat_dot.png';

// const SEAT_TEST = 'label.unknown.png';

export const NUMERIC_LABEL_OVERFLOW_THRESHOLD = 99;
export const NUMERIC_LABEL_OVERFLOW_NAME = 'overflow';

const SEAT_TEXTURE_BY_STATE: Record<PlaceGraphicalState, string> = {
  [PlaceGraphicalState.UNKNOWN]: SEAT_UNAVAILABLE_TEXTURE,
  [PlaceGraphicalState.UNAVAILABLE]: SEAT_UNAVAILABLE_TEXTURE,
  [PlaceGraphicalState.AVAILABLE]: SEAT_AVAILABLE_TEXTURE,
  [PlaceGraphicalState.SELECTED]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.PROCESSING]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.RESEATING]: SEAT_AVAILABLE_TEXTURE,
  [PlaceGraphicalState.RESEATING_COMPLETED]: SEAT_COMPLETE_TEXTURE,
  [PlaceGraphicalState.RESEATING_SELECTED]: SEAT_SELECTED_TEXTURE,
  [PlaceGraphicalState.RESEATING_AVAILABLE]: SEAT_AVAILABLE_TEXTURE,
};

let loadedResources: LoadedResources | null = null;

export async function loadResources(): Promise<LoadedResources> {
  if (loadedResources !== null) return loadedResources;

  const resources: LoadedResources = {
    [LABEL_SPRITESHEET_NAME]: await Assets.load(LABEL_SPRITESHEET_NAME),
  };
  
  loadedResources = resources;
  return resources;
}

/**
 * Gets a reference to one of the label textures.
 *
 * If there is no matching texture for the given label
 * a fallback texture will be returned.
 *
 * @param label The text of the label
 *
 * @throws if the label textures are not available.
 */
export async function getNumericLabelTexture(label: string): Promise<Texture> {
  const resources = await loadResources();
  const labelSpritesheet = resources[LABEL_SPRITESHEET_NAME];

  if (!labelSpritesheet) {
    throw new Error(`Spritesheet "${LABEL_SPRITESHEET_NAME}" not loaded`);
  }

  
  let texture = labelSpritesheet?.textures[`label.${label}.png`];
  if (!texture) {
    log.warn(
      `no texture for label "${label}" available, using "${NUMERIC_LABEL_FALLBACK_TEXTURE}" as fallback`,
    );
    texture = labelSpritesheet?.textures[NUMERIC_LABEL_FALLBACK_TEXTURE];
  }

  if (!texture) {
    throw new Error(`no texture for label "${label}" found`);
  }

  return texture;
}

export async function getNumericLabelText(label: string): Promise<Text> {
  const labelStyle = new TextStyle({
    fontFamily: 'Arial',
    fontSize: 42,
    fill: 0xFFFFFF
  });

  const labelText = new Text(label, labelStyle);
  return labelText;
}

/**
 * Returns the state specific texture for a seat.
 *
 * @param state The state for which the texture should be returned.
 *
 * @throws if there is no matching texture for the seat.
 */
export async function getSeatTexture(state: PlaceGraphicalState): Promise<Texture> {
  const resources = await loadResources();
  const texture = resources[LABEL_SPRITESHEET_NAME]?.textures[SEAT_TEXTURE_BY_STATE[state]];

  if (!texture) {
    throw new Error('no texture for seat found');
  }

  return texture;
}
