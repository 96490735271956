import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCheckout, useCheckoutState } from '../../state/Checkout';
import CheckoutSummaryComponent from '../../components/CheckoutSummary';
import Spinner from '../../common/Spinner';

const CheckoutSummaryScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { fetching } = useCheckoutState();

  useEffect(() => {
    dispatch(getCheckout());
  }, [dispatch]);

  return (
    fetching ? (
      <Spinner />
    ) : (
      <CheckoutSummaryComponent />
    )
  );
};

export default CheckoutSummaryScreen;
