import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import TicketReseating from '../TicketReseating';
import Section from '../Section';
import style from './style.module.css';
import { hydrateString } from '../../util/localization';
import { useLocale } from '../../state/Localization';
import { Place, usePlacesReseating } from '../../state/PlaceReseating';
import { useSession } from '../../state/Session';

const TicketReseatingBaskedComponent: React.FC = () => {
  const placesReseating = usePlacesReseating();
  const places = placesReseating.places;

  const { strings } = useLocale();
  const { user } = useSession();

  const [isShowInfoModal, setIsShowInfoModal] = useState(true);
  const [nextPlace, setNextPlace] = useState<Place | undefined>(undefined);

  const greetingString = hydrateString(strings.ReseatingModal_FirstRow, {
    firstName: user?.firstName ?? strings.ReseatingModal_FirstRowGuest,
  });

  function closeModal(): void {
    setIsShowInfoModal(false);
  }

  useEffect(() => {
    const place = placesReseating.places.find(
      (place) => place.id === placesReseating.selectedPlaces[0]?.placeId,
    );
    setNextPlace(place);
  }, [placesReseating.selectedPlaces]);

  return (
    <Section variant="main">
      <Modal
        isOpen={isShowInfoModal}
        className={style.Modal}
        overlayClassName={style.Overlay}
      >
        <div>
          <p>{greetingString}</p>
          <br />
          <p>{strings.ReseatingModal_Description}</p>
        </div>
        <div className={style.ModalButtons}>
          <button onClick={closeModal} className={style.CancelInfoButton}>
            {strings.ReseatingModal_Button}
          </button>
        </div>
      </Modal>

      {nextPlace && (
        <div className={style.NextSeat}>
          <strong>{strings.ReseatingBasket_NextPlace}</strong>{' '}
          {nextPlace.seasonTicketLegalRecipient}{' '}
          {strings.ReseatingBasket_NextPlaceFrom} {strings.Shared_Block}&nbsp;
          {nextPlace.blockLabel}
          {nextPlace.blockType === 'seating' && (
            <>
              {', '}
              {strings.Shared_Row}&nbsp;{nextPlace.rowLabel}
              {', '}
              {strings.Shared_Seat}&nbsp;{nextPlace.seatLabel}
            </>
          )}
        </div>
      )}

      <div className={style.Header}>
        <div className={style.HeaderSelection}>
          {strings.ReseatingBasket_Select}
        </div>
        <div className={style.HeaderInfo}>
          <strong className={style.TextHeading}>
            {strings.ReseatingBasket_Places}
          </strong>
        </div>
      </div>

      {places.map((place, key) => (
        <TicketReseating key={key} index={key} place={place} />
      ))}
    </Section>
  );
};

export default TicketReseatingBaskedComponent;
