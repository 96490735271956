import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import * as Yup from 'yup';
import useInitParams from '../../util/useInitParams';
import { useLocale } from '../../state/Localization';
import {
  getCheckout,
  useCheckoutState,
  FormCheckoutContactData,
  postCheckoutContactData
} from '../../state/Checkout';
import { getVenueEvent, useVenueEvent } from '../../state/VenueEvent';
import { filterEmptyFields, filterNullFields } from '../../util/formHelpers';
import { isExpired } from '../../components/CheckoutSummaryItem';
import CheckoutContactFields from '../../components/CheckoutContactFields';
import CheckoutSummaryComponent from '../../components/CheckoutSummary';
import { progressBarSteps } from '../../components/ProgressBar/ProgressBar';
import Drawer from '../../components/Drawer/Drawer';
import DrawerHandleSumComponent from '../../components/DrawerHandleSum';
import CountdownDialog from '../../components/CountdownDialog';
import Header from '../../components/Header/Header';
import Layout from '../../common/Layout';
import style from './style.module.css';

const CheckoutContactData: React.FC = () => {
  const dispatch = useDispatch();
  const checkoutState = useCheckoutState();
  const { initParams } = useInitParams();
  const { venueEvent } = useVenueEvent();
  const history = useHistory();

  const { tenantSlug } = useRouteMatch<{
    tenantSlug: string;
  }>().params;

  useEffect(() => {
    if (!initParams?.venueEventId || !initParams.salesChannel) return;
    dispatch(getVenueEvent(initParams.venueEventId, initParams.salesChannel, ''));
  }, [dispatch, initParams]);

  useEffect(() => {
    dispatch(getCheckout());
  }, [dispatch]);

  const onHandleSubmit = (values: FormCheckoutContactData) => {
    dispatch(postCheckoutContactData(values, history, tenantSlug));
  };

  const { formFields, strings } = useLocale();
  const [showInvoiceContact, setShowInvoiceContact] = useState(false);
  const [initialValues, setInitialValues] = useState({
    legalContact: {
      salutation: 'm',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      companyName: '',
      postalCode: '',
      city: '',
      country: '',
      email: '',
    },
    invoiceContact: {
      salutation: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      companyName: '',
      postalCode: '',
      city: '',
      country: '',
      email: '',
    },
    gdprAccepted: false,
    tosAccepted: false
  } as FormCheckoutContactData);

  useEffect(() => {
    const { legalContact, invoiceContact } = checkoutState;

    if (legalContact) {
      const updatedValues = {
        ...initialValues,
        legalContact: {
          ...initialValues.legalContact,
          ...filterNullFields(legalContact),
        },
        invoiceContact: {
          ...initialValues.invoiceContact,
          ...filterNullFields(invoiceContact),
        },
        gdprAccepted: initialValues.gdprAccepted,
        tosAccepted: initialValues.tosAccepted,
      };
      setInitialValues(updatedValues as FormCheckoutContactData);
    }
    if (invoiceContact) {
      setShowInvoiceContact(true);
    }
  }, [checkoutState]);

  const hasExpiredItem = (): boolean => {
    return !!checkoutState.events.find(
      (event) => (!!event.items.find((cartItem) => isExpired(cartItem))),
    );
  };

  const handleSubmit = (values: FormCheckoutContactData) => {
    if (values.gdprAccepted && values.tosAccepted) {
      const formDataToSend = {
        // As we will not show invoiceContact form we will always use legalContact
        // invoiceContact: checkIsAllEmptyValues(values.invoiceContact) ? values.legalContact : values.invoiceContact,
        invoiceContact: values.legalContact,
        legalContact: values.legalContact,
        gdprAccepted: values.gdprAccepted,
        tosAccepted: values.tosAccepted
      };
      const filteredValues = filterEmptyFields(formDataToSend);
      onHandleSubmit(filteredValues as FormCheckoutContactData);
    }
  };

  const handleBack = () => {
    if (
      !initParams?.salesChannel ||
      !initParams?.venueEventId ||
      !initParams?.tenantSlug
    ) return;
    history.push(
      `/${initParams.tenantSlug}/placeSelection/` +
      `${initParams.salesChannel}/${initParams.venueEventId}`
    );
  };

  const checkoutContactValidationSchema = Yup.object().shape({
    legalContact: Yup.object().shape({
      firstName: Yup.string().required(formFields.validateMessageField),
      lastName: Yup.string().required(formFields.validateMessageField),
      email: Yup.string().email('Invalid email').required(formFields.validateMessageField),
    }),
    invoiceContact: Yup.lazy(() =>
      showInvoiceContact
        ? Yup.object().shape({
          firstName: Yup.string().required(formFields.validateMessageField),
          lastName: Yup.string().required(formFields.validateMessageField),
          email: Yup.string().email('Invalid email').required(formFields.validateMessageField),
        })
        : Yup.mixed().notRequired()
    ),
    gdprAccepted: Yup.boolean().oneOf([true], formFields.validateMessageCheckboxGdprAccepted),
    tosAccepted: Yup.boolean().oneOf([true], formFields.validateMessageCheckboxTosAccepted)
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={checkoutContactValidationSchema}
      validateOnMount={false}
    >
      {({ isValid, submitForm }) => {
        return (
          <Layout
            title={strings.Checkout_ContactData_Title}
            infoText={venueEvent?.salesInformation}
            header={<Header expires={checkoutState.expiresAt} />}
            main={
              <Form>
                <CheckoutContactFields
                  className={style.formWrapper}
                  contactType='legalContact'
                  i18nFormFields={formFields}
                />
                <div className={style.formCheckboxWrapper}>
                  <div className={style.formFieldcheckbox}>
                    <Field name='gdprAccepted'
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label={
                        <>
                          {`${formFields.gdprAccepted} `}
                          <a
                            href={checkoutState?.events[0]?.gtcUrl}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {formFields.gdprAcceptedLink}
                          </a>
                        </>
                      }
                    />
                    <ErrorMessage name="gdprAccepted" component="div" className={style.formFieldErrorMessage} />
                  </div>
                  <div className={style.formFieldcheckbox}>
                    <Field name='tosAccepted'
                      as={FormControlLabel}
                      control={<Checkbox />}
                      label={
                        <>
                          {`${formFields.tosAccepted} `}
                          <a
                            href={checkoutState?.events[0]?.privacyPolicyUrl}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {formFields.tosAcceptedLink}
                          </a>
                        </>
                      }
                    />
                    <ErrorMessage name="tosAccepted" component="div" className={style.formFieldErrorMessage} />
                  </div>
                </div>

                {/* {showInvoiceContact && (
                  <div className={style.formWrapperInvoice}>
                    <h2>{formFields.invoiceContact}</h2>
                    <CheckoutContactFields
                      className={style.formWrapper}
                      contactType='invoiceContact'
                      i18nFormFields={formFields}
                    />
                  </div>
                )} */}

                <div className={style.formButtonWrapper}>
                  {initParams
                    ? <Button
                        color='secondary'
                        variant='outlined'
                        size='large'
                        startIcon={<ChevronLeft />}
                        className={style.formButton}
                        onClick={() => handleBack()}
                      >
                        {strings.Shared_Back}
                      </Button>
                    : <div></div>
                  }
                  <LoadingButton
                    loading={checkoutState.fetching}
                    loadingPosition="end"
                    color='primary'
                    variant='contained'
                    size='large'
                    endIcon={<ChevronRight />}
                    className={style.formButton}
                    disabled={!isValid || hasExpiredItem()}
                    onClick={() => { submitForm(); }}
                  >
                    {strings.Checkout_Summary_Next}
                  </LoadingButton>
                </div>
                <CountdownDialog to={checkoutState.expiresAt} />
              </Form>
            }
            aside={
              <CheckoutSummaryComponent />
            }
            progress={progressBarSteps.CONTACT}
            drawer={
              <Drawer
                title='Bestellübersicht'
                handle={<DrawerHandleSumComponent />}
                actions={
                  <div className={style.drawerActions}>
                    <Button
                      key='submit'
                      color='primary'
                      variant='contained'
                      size='large'
                      endIcon={<ChevronRight />}
                      className={style.formButton}
                      disabled={!isValid || hasExpiredItem()}
                      onClick={() => { submitForm(); }}
                    >
                      {strings.Checkout_Summary_Next}
                    </Button>
                    {initParams &&
                      <Button
                        key='back'
                        color='secondary'
                        variant='outlined'
                        size='large'
                        startIcon={<ChevronLeft />}
                        className={style.formButton}
                        onClick={() => handleBack()}
                      >
                        {strings.Shared_Back}
                      </Button>
                    }
                  </div>
                }
              >
                <CheckoutSummaryComponent />
              </Drawer>
            }
          />
        );
      }}
    </Formik>
  );
};

export default CheckoutContactData;
