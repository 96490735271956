import { Action, ActionType, TicketSelectionState } from './types';

export const reducer = (
  state: TicketSelectionState = null,
  action: Action,
): TicketSelectionState => {
  switch (action.type) {
    case ActionType.SET_STATE: {
      return action.payload.state;
    }
    default: {
      return state;
    }
  }
};
