import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from '../../state/Localization';
import { getTenantConfig, useTenantConfig } from '../../state/TenantConfig';
import './Footer.scss';

const Footer: React.FC = () => {
  const { strings } = useLocale();
  const { tenantConfig } = useTenantConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tenantConfig) {
      dispatch(getTenantConfig());
    }
  }, []);

  // TODO: Get support hotline information from API
  const hotline = 'Hotline*: 06073 / 722740';
  const availability = '* Mo.-Fr. 09:00-17:00 Uhr & Sa. 09:00 - 14:00 Uhr';

  return (
    <footer className='footer'>
      <div className="footer__wrapper">
        <div className="footer__left">
          <div className="footer__hotline">{hotline}</div>
          <div className="footer__availability">{availability}</div>
        </div>
        <div className="footer__right">
          {tenantConfig?.gtcUrl &&
            <a href={tenantConfig.gtcUrl} target="_blank" rel="noreferrer">
              {strings.Meta_Terms}
            </a>
          }
          {tenantConfig?.privacyPolicyUrl &&
            <a href={tenantConfig.privacyPolicyUrl} target="_blank" rel="noreferrer">
              {strings.Meta_Privacy}
            </a>
          }
          {tenantConfig?.imprintUrl &&
            <a href={tenantConfig.imprintUrl} target="_blank" rel="noreferrer">
              {strings.Meta_Imprint}
            </a>
          }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
