import React from 'react';
import { useLocale } from '../../../state/Localization';
import './style.scss';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

export interface CheckoutPaymentProps {
  handleMollieRequestPayment: () => Promise<void>;
}

const CheckoutPaymentComponent: React.FC<CheckoutPaymentProps> = ({
  handleMollieRequestPayment,
}) => {
  const { strings } = useLocale();

  return (
    <Button
      onClick={() => {
        handleMollieRequestPayment();
      }}
      color="primary"
      variant="contained"
      endIcon={<ChevronRight />}
    >
      {strings.Checkout_Payment_Btn}
    </Button>
  );
};

export default CheckoutPaymentComponent;
