import React, { useEffect, useRef, useState } from 'react';
import { getCheckoutFromOrderId, useCheckoutState } from '../../state/Checkout';
import {
  chargeMollieOrder,
  chargeMolliePayment,
  chargePayonePayment,
  IPayonePaymentData,
  postCheckoutStartPayment,
  postPaymentFakeCharge,
  usePaymentState,
} from '../../state/Payment';
import { getVenueEvent, useVenueEvent } from '../../state/VenueEvent';
import { Button } from '@mui/material';
import { useLocale } from '../../state/Localization';
import Spinner from '../../common/Spinner';
import { useDispatch } from 'react-redux';
import CountdownDialog from '../../components/CountdownDialog';
import FakePaymentComponent from '../../components/CheckoutPayment/FakePaymentComponent';
import PayonePaymentComponent from '../../components/CheckoutPayment/PayonePaymentComponent';
import { progressBarSteps } from '../../components/ProgressBar/ProgressBar';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Layout from '../../common/Layout';
import MolliePaymentComponent from '../../components/CheckoutPayment/MolliePaymentComponent';
import {
  getPaymentMethods,
  IPaymentMethod,
  usePaymentMethodState,
} from '../../state/PaymentMethod';
import style from '../../components/CheckoutPayment/FakePaymentComponent/style.module.css';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import CheckoutSummaryComponent from '../../components/CheckoutSummary';
import Drawer from '../../components/Drawer/Drawer';
import Header from '../../components/Header/Header';
import DrawerHandleSumComponent from '../../components/DrawerHandleSum';
import useInitParams from '../../util/useInitParams';

const CheckoutPayment: React.FC = () => {
  const { strings } = useLocale();
  const { id, uid, tenantSlug } = useRouteMatch<{
    id: string;
    uid: string;
    tenantSlug: string;
  }>().params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { initParams } = useInitParams();
  const { data: paymentMethods, fetching: paymentMethodsFetching } =
    usePaymentMethodState();
  const { status, expiresAt, fetching } = useCheckoutState();
  const { venueEvent } = useVenueEvent();
  const {
    data: paymentState,
    error: paymentError,
    fetching: paymentStateFetching,
  } = usePaymentState();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [activePaymentMethod, setActivePaymentMethod] =
    useState<IPaymentMethod | null>(null);

  const checkInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!initParams?.venueEventId || !initParams.salesChannel) return;
    dispatch(getVenueEvent(initParams.venueEventId, initParams.salesChannel, ''));
  }, [dispatch, initParams]);

  useEffect(() => {
    if (activePaymentMethod) {
      return;
    }

    if (paymentMethods.length) {
      setActivePaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods, activePaymentMethod]);

  useEffect(() => {
    if (id && uid) {
      dispatch(getCheckoutFromOrderId(id, uid));
      dispatch(getPaymentMethods());
    }
  }, [id, uid]);

  useEffect(() => {
    switch (status) {
      case 'payment_pending':
        setLoading(false);
        if (activePaymentMethod) {
          dispatch(
            postCheckoutStartPayment(id, uid, {
              paymentMethodId: activePaymentMethod.id,
              createNew: true,
            }),
          );
        }
        break;
      case 'payment_confirmation_pending':
        if (id && activePaymentMethod) {
          dispatch(
            postCheckoutStartPayment(id, uid, {
              paymentMethodId: activePaymentMethod.id,
              createNew: false,
            }),
          );
        }
        break;
      case 'payment_processing':
      case 'complete_processing':
        setLoading(true);
        if (status === 'complete_processing') {
          setLoadingMessage('Die Zahlung war erfolgreich, warten Sie auf die Bearbeitung der Bestellung...');
        } else {
          setLoadingMessage('Verarbeitung...');
        }
        checkInterval.current = setInterval(() => {
          dispatch(getCheckoutFromOrderId(id, uid));
        }, 5000);
        break;
      case 'completed':
        setLoading(false);
        history.push({ pathname: `/${tenantSlug}/checkout/${id}/${uid}` });
        break;
      case 'canceled':
        setLoading(false);
        setLoadingMessage('Payment canceled');
        break;
      case 'expired':
        setLoading(false);
        setLoadingMessage('Payment expired');
        break;
      default:
        break;
    }

    return () => {
      if (checkInterval.current) clearInterval(checkInterval.current);
    };
  }, [status, paymentState?.provider, activePaymentMethod]);

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!paymentMethods) {
      return;
    }

    const selectedMethod = paymentMethods.find(
      (item) => item.id === event.target.value,
    );

    if (!selectedMethod) {
      return;
    }

    setActivePaymentMethod(selectedMethod);
  };

  const RenderRadioProducts = () => {
    const checkedId = activePaymentMethod
      ? activePaymentMethod.id
      : paymentMethods[0].id;
    return paymentMethods.map((item, index) => {
      return (
        <label key={index}>
          <input
            type="radio"
            name={item.label}
            value={item.id}
            checked={checkedId === item.id}
            disabled={paymentMethodsFetching}
            onChange={handlePaymentMethodChange}
          />
          <img src={item.logo} alt={item.label} />
          <span>{item.label}</span>
        </label>
      );
    });
  };

  const PaymentMethodComponent = () => {
    if (activePaymentMethod === null) {
      return null;
    }

    if (paymentStateFetching) {
      return <Spinner />;
    }

    switch (activePaymentMethod.provider) {
      case 'fake':
        return <FakePaymentComponent handlePayment={handlePayment} />;
      case 'payone':
        return paymentState ? (
          <PayonePaymentComponent
            payoneData={paymentState as IPayonePaymentData}
            handlePayoneRequestPayment={handlePayoneRequestPayment}
          />
        ) : null;
      case 'mollie':
        return (
          <MolliePaymentComponent
            handleMollieRequestPayment={handleMollieRequestPayment}
          />
        );
      default:
        return null;
    }
  };

  const handlePayment = () => {
    if (paymentState) {
      dispatch(postPaymentFakeCharge(paymentState.id));
      history.push({
        pathname: `/${tenantSlug}/checkout/${id}/${uid}`,
      });
    }
  };

  const handlePayoneRequestPayment = async (
    encryptedCustomerInput: string | null,
  ) => {
    if (paymentState && id && uid && activePaymentMethod) {
      setLoading(true);
      setLoadingMessage('Verarbeitung...');
      dispatch(
        chargePayonePayment(
          paymentState.id,
          encryptedCustomerInput,
          activePaymentMethod.id,
        ),
      ); // eslint-disable-line
      if (status === 'payment_pending') {
        checkInterval.current = setInterval(() => {
          dispatch(getCheckoutFromOrderId(id, uid));
        }, 5000);
      }
    }
  };

  const handleMollieRequestPayment = async () => {
    if (
      !paymentState ||
      !id ||
      !uid ||
      !activePaymentMethod ||
      paymentState.provider != 'mollie'
    ) {
      return;
    }

    setLoading(true);
    setLoadingMessage('Verarbeitung...');

    if (paymentState.resource == 'orders') {
      dispatch(chargeMollieOrder(paymentState.id, activePaymentMethod.id));
    }
    if (paymentState.resource == 'payments') {
      dispatch(chargeMolliePayment(paymentState.id, activePaymentMethod.id));
    }
  };

  const handleBackToCheckout = () => {
    window.location.href = `/${tenantSlug}/checkout/${id}/${uid}/payment`;
  };

  if (paymentError) {
    return (
      <Layout
        title={loadingMessage}
        header={<Header />}
        main={
          <Button
            variant="outlined"
            color="error"
            onClick={handleBackToCheckout}
            style={{ marginTop: 20 }}
          >
            {strings.Checkout_Payment_BtnBackToPayment}
          </Button>
        }
        progress={progressBarSteps.PAYMENT}
      />
    );
  }

  if (loading) {
    return (
      <Layout
        title={loadingMessage}
        header={<Header />}
        infoText={venueEvent?.salesInformation}
        main={
          <>
            {status === 'canceled' ? (
              <Button
                variant="outlined"
                color="error"
                onClick={handleBackToCheckout}
              >
                {strings.Checkout_Payment_BtnBackToCheckout}
              </Button>
            ) : (
              <Spinner />
            )}
          </>
        }
        progress={status !== 'canceled' ? progressBarSteps.PAYMENT : undefined}
      />
    );
  } else if (fetching || paymentMethodsFetching) {
    return <Spinner />;
  }

  if (paymentMethodsFetching || !paymentMethods || !paymentMethods.length) {
    return (
      <Layout
        title={strings.Checkout_Summary_Empty}
        header={<Header />}
        progress={progressBarSteps.PAYMENT}
      />
    );
  }

  return (
    <Layout
      title={strings.Checkout_Payment_Title}
      header={<Header expires={expiresAt} />}
      main={
        <>
          <h3 className="paymentTitle">{strings.Checkout_Payment_Error}</h3>
          <div className="paymentTabs">
            <div className="paymentTabs__panel">
              <RenderRadioProducts />
            </div>
            <div className="paymentTabs__content">
              <div className={style.paymentButtons}>
                {initParams ? (
                  <Button
                    onClick={() => {
                      history.push(`/${initParams.tenantSlug}/checkout/data/`);
                    }}
                    color="secondary"
                    variant="outlined"
                    startIcon={<ChevronLeft />}
                  >
                    {strings.Shared_Back}
                  </Button>
                ) : (
                  <div></div>
                )}
                <PaymentMethodComponent />
              </div>
            </div>
          </div>
          <CountdownDialog to={expiresAt} />
        </>
      }
      progress={progressBarSteps.PAYMENT}
      aside={<CheckoutSummaryComponent readonly={true} />}
      drawer={
        <Drawer
          title="Bestellübersicht"
          handle={<DrawerHandleSumComponent />}
          actions={
            <div className={style.drawerActions}>
              <Button
                key="submit"
                color="primary"
                variant="contained"
                size="large"
                endIcon={<ChevronRight />}
                className={style.formButton}
                onClick={handlePayment}
              >
                {strings.Checkout_Payment_Btn}
              </Button>
              {initParams && (
                <Button
                  key="back"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  startIcon={<ChevronLeft />}
                  className={style.formButton}
                  onClick={() => {
                    history.push(`/${initParams.tenantSlug}/checkout/data/`);
                  }}
                >
                  {strings.Shared_Back}
                </Button>
              )}
            </div>
          }
        >
          <CheckoutSummaryComponent />
        </Drawer>
      }
    />
  );
};

export default CheckoutPayment;
