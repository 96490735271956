import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Default from './screens/Default/Default';
import MessageStack from './components/MessageStack';
import React from 'react';
import SelectTicket from './screens/SelectTicket';
import { Provider as StateProvider } from 'react-redux';
 import VenueEventList from './screens/SalesChannelEventList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from 'redux';
import Rmv from './screens/Rmv';
import NotFoundPage from './screens/NotFoundPage/NotFoundPage';
import RevokeTicket from './screens/RevokeTicket';
import CheckoutSummary from './screens/CheckoutSummary';
import CheckoutContactData from './screens/CheckoutContactData';
import CheckoutThanks from './screens/CheckoutThanks';
import CheckoutPayment from './screens/CheckoutPayment';

import { theme } from './util/muiTheme';
import { ThemeProvider } from '@mui/material';

const publicURL = process.env.PUBLIC_URL;

const App: React.FC<{ store: Store }> = ({ store }) => {

  return (
    <StateProvider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={publicURL}>
          <Switch>
            <Route path={'/:tenantSlug/placeSelection/:salesChannel/:venueEventId'}>
              <SelectTicket />
            </Route>
            <Route path="/:tenantSlug/revokeMarketplaceTicket/:salesChannel/:ticketId">
              <RevokeTicket />
            </Route>
            <Route path="/:tenantSlug/events">
              <VenueEventList />
            </Route>
            <Route path="/:tenantSlug/checkout/summary">
              <CheckoutSummary />
            </Route>
            <Route path="/:tenantSlug/checkout/data">
              <CheckoutContactData />
            </Route>
            <Route path="/:tenantSlug/checkout/:id/:uid/payment">
              <CheckoutPayment />
            </Route>
            <Route path="/:tenantSlug/checkout/:id/:uid">
              <CheckoutThanks />
            </Route>
            <Route path="/rmv">
              <Rmv />
            </Route>
            <Route path="/not-found">
              <NotFoundPage />
            </Route>
            <Route>
              <Default />
            </Route>
          </Switch>
          <MessageStack />
          <ToastContainer />
        </BrowserRouter>
      </ThemeProvider>
    </StateProvider>
  );
};

export default App;
